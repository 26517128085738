<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
            <h4><i class="fa fa-bar-chart"></i> All Vendors <router-link class="btn btn-md btn-primary float-right" to="/addvendor">Add New Vendor</router-link> </h4><br>
              <table class="table table-striped table-advance table-hover">
                <thead>
                  <tr>
                    <th> S/N </th>
                    <th>Vendor Name </th>
                    <th>Vendor Location </th>
                    <th> Phone </th>
                    <th> Email </th>
                    <th> Actions </th>
                </tr>
                </thead>
                <tbody>

                    <tr v-if="allUsers.length <= 0">
                        <td colspan=100%> <p class="text-center text-primary"> No Vendors has been added </p> </td>
                    </tr>

                    <tr v-for="user in allUsers" :key="user.id">
                    <td> {{user.id}} </td>
                    <td> {{user.vendor_name}} </td>
                    <td> {{user.vendor_address}} </td>
                    <td> {{user.vendor_phone}} </td>
                    <td> {{user.vendor_email}} </td>
                    <td>
                        <a class="btn btn-success btn-md" style="color:white" @click="addRider(user.id)">Add Rider</a> <br> <br>
                        <a class="btn btn-dark btn-md" style="color:white" @click="gotoVendorPhone(user.id)">Edit Phone</a> <br> <br>
                        <a class="btn btn-secondary btn-md" style="color:white" @click="gotoVendorEmail(user.id)">Edit Email</a> <br> <br>
                        <a class="btn btn-primary btn-md" style="color:white" @click="gotoVendor(user.id)">Edit Name</a> <br> <br>
                        <a class="btn btn-warning btn-md" style="color:white" @click="viewVendorRiders(user.id)">View Riders</a> <br> <br> 
                    </td>
                    </tr> 
                </tbody>
              </table>


              <div class="center">
                <paginate
                    :page-count="totalPages"
                    :page-range="9"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item page-link'"
                    :class="'pagination'"
                    :active-class="'my-active'"
                    :v-model="currentPage"
                    >
                    <span slot="prevContent">Changed previous button</span>
                    <span slot="nextContent">Changed next button</span>
                    <span slot="breakViewContent">
                    <svg width="16" height="4" viewBox="0 0 16 4">
                        <circle fill="#999999" cx="2" cy="2" r="2" />
                        <circle fill="#999999" cx="8" cy="2" r="2" />
                        <circle fill="#999999" cx="14" cy="2" r="2" />
                    </svg>
                    </span>
                </paginate>
              </div>

            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
        </div>
        <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>
import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';
import Paginate from 'vuejs-paginate';

    export default {

        mounted() {
            let adminRole = localStorage.getItem('admin_role');
            if (adminRole.toLowerCase() == 'superadmin') {
                this.getUsers(this.$baseUrl+'api/admin/vendor/get_all');
            } else {
                this.$router.push('/home');
            }
        },

        methods : {
            viewVendorRiders(id){
                this.$router.push("/vendors/rider/"+id)
            },
             addRider(id){
                    this.$router.push('/addrider/'+id);
                },

             gotoVendor(id){
                    this.$router.push('/edit/vendor/'+id);
                },

               gotoVendorEmail(id){
                    this.$router.push('/edit/vendoremail/'+id);
                },

                gotoVendorPhone(id){
                    this.$router.push('/edit/vendorphone/'+id);
                },


            clickCallback (pageNum)  {
                this.currentPage = pageNum;
                console.log("currentPage is: ", this.currentPage);
                this.getUsers(this.$baseUrl+'api/admin/vendor/get_all?page='+pageNum);
            },

            getUsers(url) {
                    //MARK
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(url, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        this.allUsers = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.perPage = response.data.per_page;
                        this.totalPages = response.data.last_page;
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Vendors", "error")
                        console.log(error);
                    });
            }

        },
            components : {
                'app-default':Default,
                loading,
                'paginate':Paginate
            },

            data(){
                return {
                    show: false,
                    label: 'Loading...',
                    overlay:true,
                    allUsers : [],
                    currentPage: 1,
                    perPage:10,
                    totalPages:10
                }
            }
        
    }
</script>