import { render, staticRenderFns } from "./AssignDispatch.vue?vue&type=template&id=16e8bf1a&scoped=true&"
import script from "./AssignDispatch.vue?vue&type=script&lang=js&"
export * from "./AssignDispatch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16e8bf1a",
  null
  
)

export default component.exports