<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
            <h4><i class="fa fa-bar-chart"></i> Dispatch Pricing <router-link class="btn btn-md btn-dark float-right" to="/addadmin">Edit Pricing</router-link> </h4><br>
              <table class="table table-striped table-advance table-hover">
                <thead>
                  <tr>
                    <th> S/N </th>
                    <th>Order Type </th>
                    <th>Delivery Method </th>
                    <th>Base Fee </th>
                    <th>Price Per Distance </th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>

                    <tr v-if="allUsers.length <= 0">
                        <td colspan=100%> <p class="text-center text-primary"> No Delivery Pricing has been added </p> </td>
                    </tr>

                    <tr v-for="user in allUsers" :key="user.id">
                    <td> {{user.id}} </td>
                    <td> {{user.order_type}} </td>
                    <td> {{user.delivery_method}} </td>
                    <td> &#8358;{{user.base_fee}} </td>
                    <td> &#8358;{{user.price_per_distance}} </td>
                    <td>  
                        <a class="btn btn-primary btn-md" style="color:white" @click="editPricing(user)">Edit</a>
                    </td>

                    </tr> 
              
                </tbody>
              </table>
            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
        </div>
        <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';

    export default {

        mounted() {
            let adminRole = localStorage.getItem('admin_role');
            if (adminRole.toLowerCase() == 'superadmin') {
                this.getPricing(this.$baseUrl+'api/admin/delivery_price');
            } else {
                this.$router.push('/home');
            }
        },

        methods : {

            editPricing(user){
                this.$router.push("/edit/pricing/"+user.id)
            },


            getPricing(url) {
                    //MARK
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(url, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        this.allUsers = response.data;
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Get Pricing", "error")
                        console.log(error);
                    });
            },
        },
            components : {
                'app-default':Default,
                loading
            },

            data(){
                return {
                    show: false,
                    label: 'Fetching Pricing...',
                    overlay:true,
                    allUsers : [],
                    currentPage: 1,
                    perPage:10,
                    totalPages:10
                }
            },
        
    }

</script>


<style lang="scss" scoped>

</style>