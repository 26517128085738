<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
                 <h4><i class="fa fa-bar-chart"></i> Add Food Item</h4>
                    <br> <hr>
                    <form class="form-horizontal style-form" @submit.prevent="onSubmit">

                        <div class="form-group"  style="margin:10px">
                        <label class="col-sm-12 col-sm-12  control-label">Food Name: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="name" class="form-control" required>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Food Price </label>
                        <div class="col-sm-12">
                            <input type="number" class="form-control" v-model="price" required>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Food Description </label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control" v-model="description" required>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Food Image </label>
                        <div class="col-sm-12">
                            <input type="file"  @change="onFileUpload" class="form-control" required>
                        </div>
                        </div>
                         <div class="showback">
                        &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-md btn-success"><i class="fa fa-cutlery"></i> Add New Food </button>
                        </div>


                    </form>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
    </div>
    <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading'

    export default {

            mounted(){
                let id = this.$route.params.id;
                this.restaurant_id = id;
            },

            components : {
                'app-default':Default,
                loading
            },

            data(){
                return {
                    restaurant_id: 1,
                    name: "",
                    price: 1,
                    description: "",
                    FILE: null,
                    show: false,
                    label: 'Loading...',
                    overlay:true,
                }
            },

            methods: {
                
                onFileUpload (event) {
                    this.FILE = event.target.files[0]
                },

                onSubmit() {
                    // upload file
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    const formData = new FormData()
                    formData.append('restaurant_id', this.restaurant_id)
                    formData.append('food_image', this.FILE, this.FILE.name)
                    formData.append('name', this.name)
                    formData.append('price', this.price)
                    formData.append('description', this.description)
                    this.axios.post(this.$baseUrl+'api/admin/food/add', formData, options, {
                    }).then((res) => {
                        console.log(res)
                        this.show = false
                        this.$swal("Success", "Food Added Successfully", "success").then(() => {
                            this.$router.push({ path: `/restaurant/${this.restaurant_id}` }) 
                        })
                    })
                    .catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Add Food", "error")
                        console.log(error);
                    });
                }  
            }
        
    }

</script>


<style lang="scss" scoped>

</style>