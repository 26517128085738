<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
                 <h4><i class="fa fa-bar-chart"></i> Add An Admin </h4>

                    <br> <hr>
                    <form class="form-horizontal style-form" @submit.prevent="onSubmit">

                        <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">First Name: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="fname" class="form-control" required>
                        </div>
                        </div>

                        <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">Last Name: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="lname" class="form-control" required>
                        </div>
                        </div>

                        <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">Email Address: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="email" class="form-control" required>
                        </div>
                        </div>

                           <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">Phone Number: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="phone" class="form-control" required>
                        </div>
                        </div>

                        <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">Password: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="password" class="form-control" required>
                        </div>
                        </div>


                        <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">Confirm Password: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="repassword" class="form-control" required>
                        </div>
                        </div>
                        <div class="showback">
                        &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-md btn-success"><i class="fa fa-user-plus"></i> Add New Admin </button>
                        </div>


                    </form>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
    </div>
    <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';

    export default {

            components : {
                'app-default':Default,
                loading
            },

            data(){
                return {
                    show: false,
                    label: 'Adding Admin...',
                    overlay:true,
                    fname: "",
                    lname: "",
                    email: "",
                    phone: "",
                    password:"",
                    repassword:"",
                }
            },

            methods: {
                onSubmit() {
                    // upload file

                    if (this.password != this.repassword) {
                        this.$swal("Password Mismatch", "Password and Confirm Password doesn't match", "info")
                    }else{
                            this.show = true
                            let token = localStorage.getItem('delivery_app_token');
                            const tokenValue = "Bearer "+token;
                            const options = {
                                headers: {'Authorization': tokenValue}
                            };
                            const formData = new FormData()
                            formData.append('first_name', this.fname)
                            formData.append('last_name', this.lname)
                            formData.append('email', this.email)
                            formData.append('phone_number', this.phone)
                            formData.append('password', this.password)
                            this.axios.post(this.$baseUrl+'api/admin/add', formData, options, {
                            }).then((res) => {
                                console.log(res)
                                this.show = false
                                this.$swal("Success", "New Admin Added Successfully", "success").then(() => {
                                    this.$router.replace({"name":"Admins"});
                                })
                            })
                            .catch(error => {
                                this.show = false
                                this.$swal("Error", "Failed to Add Admin", "error")
                                console.log("Error is: ");
                                console.log(error);
                            });
                    }
                }  
            }
        
    }

</script>


<style lang="scss" scoped>

</style>