<template>
    <div>
        <app-default></app-default>
        <loading 
        :show="show"
        :label="label"
        :overlay="overlay"
        ></loading>
            <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
            <h4><i class="fa fa-bar-chart"></i> Restaurant Foods <a  v-if="adminRole==isSuperAdmin"  class="btn btn-md btn-dark float-right" style="margin-right:10px"  @click="addFoodItem" href="javascript:;">Add Food Item </a> </h4><br>
            <ul  class="list-group">
                <li class="list-group-item">
                    Name: {{restaurantDetails.name}}
                </li>
                <li class="list-group-item">
                    Location: {{restaurantDetails.location}}
                </li>
                <li class="list-group-item">
                    Address: {{restaurantDetails.address}}
                </li>
                <li class="list-group-item">
                    Phone Number: {{restaurantDetails.phone_number}}
                </li>
                <li class="list-group-item">
                    Description: {{restaurantDetails.description}}
                </li>
            </ul>
            <br>
            <br>
              <table class="table table-striped table-advance table-hover">
                <thead> 
                  <tr>
                    <th> S/N </th>
                    <th>Name </th>
                    <th>Price </th>
                    <th> Description </th>
                    <th> Image </th>
                    <th> Status </th>
                    <th> Action </th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-if="foods.length <= 0">
                        <td colspan=100%> <p class="text-center text-primary"> No Food in Restaurant </p> </td>
                    </tr>

                    <tr v-for="food in foods" :key="food.id">
                    <td> {{food.id}} </td>
                    <td> {{food.name}} </td>
                    <td> {{food.price}} </td>
                    <td> {{food.description}} </td>
                    <td> <img class="img-responsive img-thumbnail" :src=" baseImageUrl+ 'food_images/' + food.food_image " style="height: 150px; width: 150px" alt="Food image"> </td>
                    <td>
                        <p v-if="food.disabled=='0'"> In Stock - Available to Customers </p>
                        <p v-if="food.disabled=='1'"> Out of Stock - Customers can't order this item now </p>
                    </td>
                    <td>  
                        <a class="btn btn-warning btn-md" style="color:white" @click="editFood(food.id)">Edit</a>
                        <br><br>
                            <button @click="disableFood(food.id)" v-if="food.disabled=='0'" class="btn btn-md btn-danger"  href="javascript:;">Disable</button> 
                            <button @click="enableFood(food.id)" v-if="food.disabled=='1'" class="btn btn-md btn-success"  href="javascript:;">Enable</button> 
                    </td>
                    </tr> 
              
                </tbody>
              </table>
            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
        </div>
        <!-- /row -->
    
    
    </section>
    </section>
        
    </div>
</template>

<script>
import Default from '../Template/Default.vue';
import loading from 'vue-full-loading'

    export default {

        mounted() {
            let id = this.$route.params.id;
            this.restaurant_id = id;
            this.getRestaurantFood(id);
            this.getRestaurantDetails(id);
        },

        methods : {

            editFood(id){
                this.$router.push("/edit/food/"+id)
            },

            getRestaurantFood(id) {
                    //MARK
                    console.log("id is: "+id);
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(this.$baseUrl+'api/admin/food/get_all_food/'+id, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        this.foods = response.data;
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Restaurant Foods", "error")
                        console.log(error);
                    });
            },

            getRestaurantDetails(id) {
                    //MARK
                    console.log("id is: "+id);
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(this.$baseUrl+'api/admin/restaurant/get_by_id/'+id, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        this.restaurantDetails = response.data;
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Restaurant Foods", "error")
                        console.log(error);
                    });
            },

            addFoodItem(){
                this.$router.push({ path: `/foods/add/${this.restaurant_id}` }) 
            },

            disableFood(id) {
          this.show = true
          let token = localStorage.getItem('delivery_app_token');
          const tokenValue = "Bearer "+token;
          const options = {
              headers: {'Authorization': tokenValue}
          };
          this.axios.post(this.$baseUrl+'api/admin/food/disable/'+id, {}, options, {
          }).then((res) => {
              console.log(res)
              this.show = false
              this.$swal("Success", "Food is now marked out of stock", "success").then(() => {
                this.getRestaurantFood(this.restaurant_id);
              })
          })
          .catch(error => {
              this.show = false
              this.$swal("Error", "Failed to out of stock this food", "error")
              console.log(error);
          });
        },
        enableFood(id) {
            this.show = true
            let token = localStorage.getItem('delivery_app_token');
            const tokenValue = "Bearer "+token;
            const options = {
                headers: {'Authorization': tokenValue}
            };
            this.axios.post(this.$baseUrl+'api/admin/food/restore/'+id, {}, options, {
            }).then((res) => {
                console.log(res)
                this.show = false
                this.$swal("Success", "Food Restocked Successfully", "success").then(() => {
                    this.getRestaurantFood(this.restaurant_id);
                })
            })
            .catch(error => {
                this.show = false
                this.$swal("Error", "Failed to Restock food", "error")
                console.log(error);
            });
          },

        },
            components : {
                'app-default':Default,
                loading
            },

            data(){
                return {
                    restaurant_id:"",
                    show: false,
                    foods : [],
                    restaurantDetails : {},
                    label: 'Loading...',
                    overlay:true,
                    baseImageUrl: this.$storageUrl
                }
            },

    computed : {
       
          adminRole(){
            let adminRole = localStorage.getItem('admin_role');
            return adminRole.toLowerCase();
          },

          isSuperAdmin(){
            return 'superadmin';
          }

        }
        
    }

</script>


<style lang="scss" scoped>

</style>