<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
            <h4><i class="fa fa-bar-chart"></i> Vendor Rides  </h4><br>

              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link"  :class="currentView==1?'active':''" aria-current="page"  @click="viewRequests(1)">Ride Requests</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"  :class="currentView==2?'active':''" @click="viewRequests(2)">Food Requests</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"   :class="currentView==3?'active':''" @click="viewRequests(3)">Cash Requests</a>
                </li>
              </ul>

              <table class="table table-striped table-advance table-hover">
                <thead>

                  <tr v-if="currentView == 1">
                    <th> S/N </th>
                    <th>Customer Name </th>
                    <th>Pickup </th>
                    <th>Destination</th>
                    <th>Item</th>
                    <th>Distance</th>
                    <th>Amount</th>
                    <th>Sender Name / Phone  </th>
                    <th> Receiver Name / Phone </th>
                    <th> Delivery Person </th>
                    <th> Delivery Method </th>
                    <th>Status</th>
                    <th> Date Created </th>
                    <th> Time Created</th>
                 </tr>

                 <tr v-if="currentView == 2">
                    <th> S/N </th>
                    <th>Customer Name </th>
                    <th>Address </th>
                    <th>Phone</th>
                    <th>Amount</th>
                    <th> Delivery Method </th>
                    <th>Status</th>
                    <th> Date Created </th>
                    <th> Time Created</th>
                 </tr>

                 <tr v-if="currentView == 3">
                    <th> S/N </th>
                    <th>Customer Name </th>
                    <th>Address </th>
                    <th>Phone</th>
                    <th>Amount</th>
                    <th> Delivery Method </th>
                    <th>Status</th>
                    <th> Date Created </th>
                    <th> Time Created</th>
                 </tr>


                </thead>

                <tbody v-if="currentView == 1">
                    <tr v-if="allAdminLogs.length <= 0">
                        <td colspan=100%> <p class="text-center text-primary"> No Rides Orders available yet. </p> </td>
                    </tr>

                        <tr v-for="user in allAdminLogs" :key="user.id">
                        <td> {{user.id}} </td>
                        <td> {{user.customer_name}} </td>
                        <td> {{user.pickup}} </td>
                        <td> {{user.destination}} </td>
                        <td> {{user.item}} </td>
                        <td> {{user.distance}} </td>
                        <td> &#8358;{{Number(Number(user.price).toFixed(2)).toLocaleString()}} </td>
                        <td> {{user.sender_name}} ({{user.sender_phone}}) </td>
                        <td> {{user.receiver_name}} ({{user.receiver_phone}}) </td>
                        <td> {{user.delivery_person}} </td>
                        <td> {{user.delivery_type}} </td>
                        
                        <td v-if="user.cancel_request==1 && user.status=='PENDING'"> <span class="badge badge-dark">CANCELLATION REQUESTED</span> </td>
                        <td v-else> 
                               <span v-if="user.status=='PENDING'" class="badge badge-primary">Pending</span>
                               <span v-if="user.status=='PROCESSING'" class="badge badge-warning">Running</span>
                               <span v-if="user.status=='COMPLETED'" class="badge badge-success">Completed</span>
                               <span v-if="user.status=='DECLINED'" class="badge badge-danger">Declined</span>
                        </td>

                        <td> {{user.created_at.substring(0,10)}} </td>
                        <td> {{user.created_at.substring(11,16)}} </td>
                        </tr> 
                 </tbody>

                 <tbody v-if="currentView == 2">
                    <tr v-if="allAdminLogs.length <= 0">
                        <td colspan=100%> <p class="text-center text-primary"> No Food Requests available yet. </p> </td>
                    </tr>

                        <tr v-for="user in allAdminLogs" :key="user.id">
                        <td> {{user.id}} </td>
                        <td> {{user.customer_name}} </td>
                        <td> {{user.customer_address}} </td>
                        <td> {{user.customer_phone}} </td>
                        <td> &#8358;{{Number(Number(user.delivery_amount).toFixed(2)).toLocaleString()}} </td>
                        <td> {{user.delivery_method}} </td>
                        <td v-if="user.cancel_request==1 && user.status=='PENDING'"> <span class="badge badge-dark">CANCELLATION REQUESTED</span> </td>
                        <td v-else> 
                               <span v-if="user.status=='PENDING'" class="badge badge-primary">Pending</span>
                               <span v-if="user.status=='PROCESSING'" class="badge badge-warning">Running</span>
                               <span v-if="user.status=='COMPLETED'" class="badge badge-success">Completed</span>
                               <span v-if="user.status=='DECLINED'" class="badge badge-danger">Declined</span>
                        </td>
                        <td> {{user.createdAt.substring(0,10)}} </td>
                        <td> {{user.createdAt.substring(11,16)}} </td>
                        </tr> 
                 </tbody>

                 <tbody v-if="currentView == 3">
                    <tr v-if="allAdminLogs.length <= 0">
                        <td colspan=100%> <p class="text-center text-primary"> No Cash Requests available yet. </p> </td>
                    </tr>

                        <tr v-for="user in allAdminLogs" :key="user.id">
                        <td> {{user.id}} </td>
                        <td> {{user.customer_name}} </td>
                        <td> {{user.address}} </td>
                        <td> {{user.customer_phone}} </td>
                        <td> &#8358;{{Number(Number(user.amount).toFixed(2)).toLocaleString()}} </td>
                        <td> {{user.delivery_method}} </td>
                        <td v-if="user.cancel_request==1 && user.status=='PENDING'"> <span class="badge badge-dark">CANCELLATION REQUESTED</span> </td>
                        <td v-else> 
                               <span v-if="user.status=='PENDING'" class="badge badge-primary">Pending</span>
                               <span v-if="user.status=='PROCESSING'" class="badge badge-warning">Running</span>
                               <span v-if="user.status=='COMPLETED'" class="badge badge-success">Completed</span>
                               <span v-if="user.status=='DECLINED'" class="badge badge-danger">Declined</span>
                        </td>
                        <td> {{user.created_at.substring(0,10)}} </td>
                        <td> {{user.created_at.substring(11,16)}} </td>
                        </tr> 
                 </tbody>

              </table>

              <div class="center">
                <paginate
                    :page-count="totalPages"
                    :page-range="9"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item page-link'"
                    :class="'pagination'"
                    :active-class="'my-active'"
                    :v-model="currentPage"
                    >
                    <span slot="prevContent">Changed previous button</span>
                    <span slot="nextContent">Changed next button</span>
                    <span slot="breakViewContent">
                    <svg width="16" height="4" viewBox="0 0 16 4">
                        <circle fill="#999999" cx="2" cy="2" r="2" />
                        <circle fill="#999999" cx="8" cy="2" r="2" />
                        <circle fill="#999999" cx="14" cy="2" r="2" />
                    </svg>
                    </span>
                </paginate>
              </div>

            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
        </div>
        <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';
import Paginate from 'vuejs-paginate';

    export default {

        mounted() {
            let adminRole = localStorage.getItem('admin_role');
            if (adminRole.toLowerCase() == 'superadmin') {
                let id = this.$route.params.id;
                this.getCashOrders(this.$baseUrl+'api/admin/get_cash_orders_by_rider_id/', id);
                this.getFoodOrders(this.$baseUrl+'api/admin/get_food_orders_by_rider_id/', id);
                this.getRidesOrders(this.$baseUrl+'api/admin/get_ride_orders_by_rider_id/', id);
            } else {
                this.$router.push('/home');
            }
        },

        methods : {
            clickCallback (pageNum)  {
                console.log("currentPage is: ", this.currentPage);
                let id = this.$route.params.id;
                
                if (this.currentView == 1) {
                    this.rideCurrentPage = pageNum;
                    this.getRidesOrders(this.$baseUrl+'api/admin/get_ride_orders_by_rider_id?page='+pageNum+'/', id);
                } else if (this.currentView == 2) {
                    this.foodCurrentPage = pageNum;
                    this.getFoodOrders(this.$baseUrl+'api/admin/get_food_orders_by_rider_id?page='+pageNum+'/', id);
                }else{
                    this.cashCurrentPage = pageNum;
                    this.getCashOrders(this.$baseUrl+'api/admin/get_cash_orders_by_rider_id?page='+pageNum+'/', id);
                }
            },

            viewRequests(id) {
                if (id == 1) {
                    //ride
                    this.currentView = 1;
                    this.allAdminLogs = this.ridesOrderArray;
                    this.currentPage = this.rideCurrentPage;
                    this.perPage = this.ridePerPage;
                    this.totalPages = this.rideTotalPage;
                } else if (id == 2) {
                    //food
                    this.currentView = 2;
                    this.allAdminLogs = this.foodsOrderArray;
                    this.currentPage = this.foodCurrentPage;
                    this.perPage = this.foodPerPage;
                    this.totalPages = this.foodTotalPage;
                } else {
                    //cash
                    this.currentView = 3;
                    this.allAdminLogs = this.cashsOrderArray;
                    this.currentPage = this.cashCurrentPage;
                    this.perPage = this.cashPerPage;
                    this.totalPages = this.cashTotalPage;
                }
            },

            getRidesOrders(url, id) {
                    //MARK
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(url+id, options)
                    .then((response) => {
                        this.show = false

                        console.log("For Ride: ");
                        console.log(response.data);
                        
                        this.ridesOrderArray = response.data.data ?? [];

                        this.rideCurrentPage = response.data.current_page;
                        this.ridePerPage = response.data.per_page;
                        this.rideTotalPage = response.data.last_page;

                        this.viewRequests(1);
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Ride Orders", "error")
                        console.log(error);
                    });
            },

            getFoodOrders(url, id) {
                    //MARK
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(url+id, options)
                    .then((response) => {
                        this.show = false
                        console.log("For Food: ");
                        console.log(response.data);
                        
                        this.foodsOrderArray = response.data.data ?? [];
                        this.foodCurrentPage = response.data.current_page;
                        this.foodPerPage = response.data.per_page;
                        this.foodTotalPage = response.data.last_page;

                        this.viewRequests(2);
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Food Orders", "error")
                        console.log(error);
                    });
            },
            

            getCashOrders(url,id) {
                    //MARK
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(url+id, options)
                    .then((response) => {
                        this.show = false

                        console.log("For Cash: ");
                        console.log(response.data);
                        
                        this.cashsOrderArray = response.data.data ?? [];

                        this.cashCurrentPage = response.data.current_page;
                        this.cashPerPage = response.data.per_page;
                        this.cashTotalPage = response.data.last_page;


                        this.viewRequests(3);
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Cash Orders", "error")
                        console.log(error);
                    });
            },
        },
            components : {
                'app-default':Default,
                loading,
                'paginate':Paginate
            },

            data(){
                return {
                    show: false,
                    label: 'Fetching All Rides...',
                    overlay:true,
                    allAdminLogs : [],
                    ridesOrderArray : [],
                    foodsOrderArray : [],
                    cashsOrderArray : [],
                    currentView : 1,
                    
                    currentPage: 1,
                    perPage:10,
                    totalPages:10,

                    rideCurrentPage:1,
                    foodCurrentPage:1,
                    cashCurrentPage:1,
                    
                    ridePerPage:10,
                    foodPerPage:10,
                    cashPerPage:10,

                    rideTotalPage:10,
                    foodTotalPage:10,
                    cashTotalPage:10,
                }
            },
        
    }

</script>


<style lang="scss" scoped>

</style>