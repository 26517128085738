import { render, staticRenderFns } from "./EditVendorPhone.vue?vue&type=template&id=81a2780e&scoped=true&"
import script from "./EditVendorPhone.vue?vue&type=script&lang=js&"
export * from "./EditVendorPhone.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81a2780e",
  null
  
)

export default component.exports