<template>
    <div>
    <app-default></app-default>
     <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
                 <h4><i class="fa fa-bar-chart"></i> Edit Price</h4>
                    <br> <hr>
                    <form class="form-horizontal style-form" @submit.prevent="onSubmit">

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12  control-label">Delivery Type: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="food.order_type" class="form-control" disabled>
                        </div>
                        </div>

                        
                        <div class="form-group" style="margin:10px">
                            <label class="col-sm-12 col-sm-12 control-label"> Delivery Method </label>
                            <div class="col-sm-12">
                                <input type="text" class="form-control" v-model="food.delivery_method" disabled>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Price Per Distance </label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control" v-model="food.price_per_distance" required>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Edit Base Fee </label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control" v-model="food.base_fee" required>
                        </div>
                        </div>

                        <div class="showback">
                        &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-md btn-primary"><i class="fa fa-money"></i> Edit Price </button>
                        </div>


                    </form>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
    </div>
    <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading'

    export default {

            mounted(){
                let id = this.$route.params.id;
                this.pricingID = id;
                console.log("this.pricingID: "+this.pricingID);
                this.getPricing(this.$baseUrl+'api/admin/delivery_price');
            },

            components : {
                'app-default':Default,
                loading
            },

            data(){
                return {
                    pricingID:"",
                    food: {},
                    FILE: null,
                    show: false,
                    label: 'Loading...',
                    overlay:true,
                }
            },

            methods: {

                
                getPricing(url) {
                    //MARK
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(url, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        let array =  response.data;
                        this.food = array.find(item => item.id == this.pricingID);
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Get Pricing", "error")
                        console.log(error);
                    });
            },
                
                onSubmit() {
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    let params = {"id": this.pricingID, "price_per_distance": this.food.price_per_distance, "base_fee":this.food.base_fee};
                    this.axios.put(this.$baseUrl+'api/admin/delivery_price', params, options, {
                    }).then((res) => {
                        console.log(res)
                        this.show = false
                        this.$swal("Success", "Pricing Info updated Successfully", "success").then(() => {
                            this.$router.replace({"name":"Pricing"});
                        })
                    })
                    .catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Edit Pricing", "error")
                        console.log("Error is: ");
                        console.log(error);
                    });
                }  
            }
        
    }

</script>


<style lang="scss" scoped>

</style>