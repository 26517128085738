<template>
    <div>
    <app-default></app-default>
     <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
                 <h4><i class="fa fa-bar-chart"></i> Edit Item</h4>
                    <br> <hr>
                    <form class="form-horizontal style-form" @submit.prevent="onSubmit">

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12  control-label">Food Name: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="food.name" class="form-control" required>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Food Price </label>
                        <div class="col-sm-12">
                            <input type="number" class="form-control" v-model="food.price" required>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Food Description </label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control" v-model="food.description" required>
                        </div>
                        </div>

                         <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Food Image (Optional) </label>
                        <div class="col-sm-12">
                            <input type="file"  @change="onFileUpload" class="form-control">
                        </div>
                        </div> 
                        
                        <div class="showback">
                        &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-md btn-primary"><i class="fa fa-cutlery"></i> Edit Food </button>
                        </div>


                    </form>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
    </div>
    <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading'

    export default {

            mounted(){
                let id = this.$route.params.id;
                this.getFoodDetails(id);
            },

            components : {
                'app-default':Default,
                loading
            },

            data(){
                return {
                    food: {},
                    FILE: null,
                    show: false,
                    label: 'Loading...',
                    overlay:true,
                }
            },

            methods: {

                getFoodDetails(id){
                      //MARK
                    console.log("id is: "+id);
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(this.$baseUrl+'api/admin/food/get_by_id/'+id, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        this.food = response.data;
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Restaurant Foods", "error")
                        console.log(error);
                    });
                },
                
                onFileUpload (event) {
                    this.FILE = event.target.files[0]
                },

                onSubmit() {
                    // upload file
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    const formData = new FormData()
                    formData.append('food_id', this.food.id)
                    if (this.FILE != null) {
                        formData.append('food_image', this.FILE, this.FILE.name)
                    }
                    formData.append('name', this.food.name)
                    formData.append('price', this.food.price)
                    formData.append('description', this.food.description)
                    this.axios.post(this.$baseUrl+'api/admin/food/update', formData, options, {
                    }).then((res) => {
                        console.log(res)
                        this.show = false
                        this.$swal("Success", "Food Edited Successfully", "success").then(() => {
                            this.$router.back();
                        })
                    })
                    .catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Add Food", "error")
                        console.log(error);
                    });
                }  
            }
        
    }

</script>


<style lang="scss" scoped>

</style>