<template>
<aside>
      
      <div id="sidebar" class="nav-collapse ">
        <!-- sidebar menu start-->
        <ul class="sidebar-menu" id="nav-accordion">
          <h5 class="centered">Welcome - Admin </h5>
          <li><a href="/home" :class="currentUrl=='/home'?'active':''"><i class="fa fa-home"></i><span>All Restaurants</span></a></li>
          <li><a href="/foodorders" :class="currentUrl=='/foodorders'?'active':''"><i class="fa fa-cutlery"></i><span>All Food Orders</span></a></li>
          <li><a href="/dispatchorders" :class="currentUrl=='/dispatchorders'?'active':''"><i class="fa fa-motorcycle"></i><span>All Dispatch Orders</span></a></li>
          <li><a href="/cashrequests" :class="currentUrl=='/cashrequests'?'active':''"><i class="fa fa-money"></i><span>All Cash Requests</span></a></li>
          <li><a href="/users" :class="currentUrl=='/users'?'active':''"><i class="fa fa-users"></i><span>All Registered Users</span></a></li>
          <li v-if="adminRole==isSuperAdmin"><a href="/vendors" :class="currentUrl=='/vendors'?'active':''"><i class="fa fa-user-circle-o"></i><span>All Vendors</span></a></li>
          <li v-if="adminRole==isSuperAdmin"><a href="/pricing" :class="currentUrl=='/pricing'?'active':''"><i class="fa fa-money"></i><span>Dispatch Pricing</span></a></li>
          <li v-if="adminRole==isSuperAdmin"><a href="/logsuser" :class="currentUrl=='/logsuser'?'active':''"><i class="fa fa-users"></i><span>All User Logs</span></a></li>
          <li v-if="adminRole==isSuperAdmin"><a href="/admins" :class="currentUrl=='/admins'?'active':''"><i class="fa fa-user-circle"></i><span>All Admins</span></a></li>
          <li v-if="adminRole==isSuperAdmin"><a href="/logsadmin" :class="currentUrl=='/logsadmin'?'active':''"><i class="fa fa-cogs"></i><span>Admins Logs</span></a></li>
          <li v-if="adminRole==isSuperAdmin"><a href="/admintransactions" :class="currentUrl=='/admintransactions'?'active':''"><i class="fa fa-users"></i><span>User Transactions</span></a></li>
          <li v-if="adminRole==isSuperAdmin"><a href="/fundrequestlogs" :class="currentUrl=='/fundrequestlogs'?'active':''"><i class="fa fa-money"></i><span>Fund Request Logs</span></a></li>
          <li v-if="adminRole==isSuperAdmin"><a href="/deductrequestlogs" :class="currentUrl=='/deductrequestlogs'?'active':''"><i class="fa fa-money"></i><span>Deduct Request Logs</span></a></li>
          <li><a href="/changeinfo" :class="currentUrl=='/changeinfo'?'active':''"><i class="fa fa-user"></i><span>Change Info</span></a></li>
          <li><a href="/changepassword" :class="currentUrl=='/changepassword'?'active':''"><i class="fa fa-cog"></i><span>Change Password</span></a></li>
          <br><br><br><br>
        </ul>
        <!-- sidebar menu end-->
      </div>
    </aside>
</template>

<script>
    export default {
        computed : {
          currentUrl(){
            let fullPath = this.$route.fullPath;
            console.log(fullPath);
            return fullPath;
          },

          adminRole(){
            let adminRole = localStorage.getItem('admin_role');
            return adminRole.toLowerCase();
          },

          isSuperAdmin(){
            return 'superadmin';
          }

        }
    }
</script>

<style lang="scss" scoped>
#sidebar {
  z-index: 1000;
  position: fixed;
  left: 175px;
  top: 52px;
  bottom: 0px;
  margin-left: -175px;
  overflow-y: auto;
  background: #222222;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
</style>