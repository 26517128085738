import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn : false
  },
  mutations: {
    authLogin(state, payload) {
     state.isLoggedIn = payload;
    }
  },
  actions: {
    authLogin(state, payload) {
      state.commit('authLogin', payload);
    }
  },
  modules: {
  },
  getters : {
    isUserLoggedIn(state) {
      return state.isLoggedIn;
    }
  }
})
