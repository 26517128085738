<template>
  <div>
        <head>	 
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta name="description" content="">
          <meta name="author" content="Dashboard">
          <meta name="keyword" content="Send Sms">
          <title>Elpizo Admin Login </title>
        </head>

         <body>
            <loading 
                  :show="show"
                  :label="label"
                  :overlay="overlay"
                  ></loading>
          <div id="login-page">
            <div class="container">
              
              <form class="form-login" @submit="handleLogin" >
                <h2 class="form-login-heading">Admin Sign in</h2>
                <div class="login-wrap">
                  <input type="email" class="form-control" v-model="email" autofocus required>
                  <br>
                  <input type="password" class="form-control" v-model="password" required> <br />
                  <button class="btn btn-theme btn-block" name="login" type="submit" ><i class="fa fa-lock"></i> SIGN IN</button>
                </div>
              </form>
            </div>
          </div>
        </body>
  </div>
</template>

<script>
  import loading from 'vue-full-loading'
  export default {
    components: {
      loading
    },
    mounted() {
      
    },
    data(){
      return {
        email: '',
        password: '',
        show: false,
        label: 'Logging In...',
        overlay:true
      }
    },

    methods : {
      handleLogin(event){
        event.preventDefault();


            let isMerchant = this.email.includes("merchant");

            if (isMerchant == true) {
              this.$swal('Access Denied!','visit the merchant dashboard to access your portal','error');
              return;
            }
          
        this.show = true
        let url = this.$baseUrl+'api/admin/auth/login';
        let params = 
        {email: this.email,
          password: this.password};
        this.axios.post(url, params).then((response) => {
          console.log("response is: ", response);
          this.show = false
          if (response.data.access_token != null) {
            localStorage.setItem('isLoggedIn', true);
            localStorage.setItem('delivery_app_token', response.data.access_token);
            localStorage.setItem('current_user_email', this.email);
            this.getAdminProfile();
          }else{
              this.show = false
              this.$swal('Login Failed','incorrect email or password','error');
          }
        }).catch(error => {
                  this.show = false
                  this.$swal('Login Failed','incorrect email or password','error')
                  console.log("Error is: ");
                  console.log(error);
              });
      },


      getAdminProfile(){
          this.show = true;
          let bearerToken = localStorage.getItem('delivery_app_token');
          const options = {
              headers: { Authorization: `Bearer ${bearerToken}` }
          };
          this.axios.get(this.$baseUrl+'api/admin/profile', options).then((response) => {
              console.log("profile: ", response);
              this.show = false;
              console.log(response.data);
              localStorage.setItem('admin_role', response.data.role);
              localStorage.setItem('admin_phone', response.data.phone_number);
              localStorage.setItem('admin_fname', response.data.first_name);
              localStorage.setItem('admin_lname', response.data.last_name);
              localStorage.setItem('admin_email', response.data.email);
              localStorage.setItem('admin_id', response.data.id);
              this.$router.push('/home');      
          }
          )
        },

    }
}
</script>

<style lang="scss" scoped>
  
</style>
