<template>
    <div>
        <Default/>
         <loading 
            :show="show"
            :label="label"
            :overlay="overlay"
            ></loading>
        <section  id="main-content">
            <section  class="wrapper site-min-height">
                <div  class="row mt">
                    <div class="col-md-12">
                        <div class="content-panel">
                            <h4><i class="fa fa-bar-chart"></i> Add New Restaurant </h4><br><hr>
                            <form  class="form-horizontal style-form" @submit="handleAddRestaurant" >
                                <div class="form-group" style="margin:10px">
                                        <div class="col-sm-12">
                                            <label>Restaurant Name: </label>
                                            <input type="text" v-model="name" required="required" class="form-control">
                                        </div>
                                </div>
                                
                                <div class="form-group"  style="margin:10px">
                                        <div  class="col-sm-12">
                                            <label> Restaurant Address </label>
                                            <input  type="text" v-model="address" required="required" class="form-control">
                                        </div>
                                </div>
                                
                                <div  class="form-group"  style="margin:10px">
                                        <div  class="col-sm-12">
                                            <label> Restaurant State </label>
                                            <input  type="text" v-model="location" required="required" class="form-control">
                                        </div>
                                </div>
                                                                
                                <div  class="form-group" style="margin:10px">
                                    <div  class="col-sm-12">
                                        <label> Restaurant Phone Number </label>
                                        <input  type="number" v-model="phone_number" required="required" class="form-control">
                                    </div>
                                </div>
                                
                                <div  class="form-group" style="margin:10px">
                                        <div  class="col-sm-12">
                                            <label> Restaurant Latitude </label>
                                            <input  type="text" v-model="latitude" required="required" class="form-control">
                                        </div>
                                </div>
                                
                                <div  class="form-group" style="margin:10px">
                                        <div  class="col-sm-12">
                                            <label> Restaurant Longitude </label>
                                            <input  type="text" v-model="longitude" required="required" class="form-control">
                                        </div>
                                </div>
                                
                                <div  class="form-group" style="margin:10px">
                                        <div  class="col-sm-12">
                                            <label> Restaurant Description </label>
                                            <input  type="text" v-model="description" required="required" class="form-control">
                                        </div>
                                </div>
                                
                                <div  class="form-group" style="margin:10px">
                                        <div  class="col-sm-12">
                                            <label> Restaurant Rating </label>
                                            <input  type="number" v-model="rating" required="required" class="form-control">
                                        </div>
                                </div>
                                
                                <div  class="form-group" style="margin:10px">
                                        <div  class="col-sm-12">
                                            <label> Restaurant Image </label>
                                            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" required="required" class="form-control">
                                        </div>
                                </div>
                                
                                <div  class="showback"> 
                                    <button  type="submit" name="change_password" class="btn btn-md btn-success">
                                        <i  class="fa fa-home"></i> Add New Restaurant 
                                    </button>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
    import Default from '../Template/Default.vue'
    import loading from 'vue-full-loading'
  
  export default {
      components : {Default, loading},

        data(){
            return {
                name:'',
                address:'',
                location:'',
                description:'',
                rating:'',
                longitude: 1,
                latitude: 1,
                restaurant_image:'',
                phone_number:'',
                show: false,
                label: 'Adding Restaurant...',
                overlay:true
            }
        },

        methods : {
             handleFileUpload() {
                console.log('restaurant imgs...');
                this.restaurant_image = this.$refs.file.files[0];
                console.log(this.restaurant_image);
            },

            handleAddRestaurant(e){
                e.preventDefault();
                this.show = true
                let bearerToken = localStorage.getItem('delivery_app_token');
                const options = { 'Authorization': `Bearer ${bearerToken}`, 'Content-Type': 'multipart/form-data'};
                let formData = new FormData()
                formData.append('name', this.name)
                formData.append('address', this.address)
                formData.append('location', this.location)
                formData.append('phone_number', this.phone_number)
                formData.append('description', this.description)
                formData.append('rating', this.rating)
                formData.append('restaurant_long', this.longitude)
                formData.append('restaurant_lat', this.latitude)
                formData.append('restaurant_image', this.restaurant_image)
                let url = this.$baseUrl+'api/admin/restaurant/add';
                console.log(url);
                // Display the key/value pairs
                for (var pair of formData.entries()) {
                    console.log(pair[0]+ ' : ' + pair[1]); 
                }
                this.axios({
                method: "post",
                url: url,
                data: formData,
                headers: options,
                })
                .then(res => {
                        console.log("add restaurant response: ", res)
                        this.show = false
                        this.$swal("Success", "Restaurant Added Successfully", "success").then(() => {
                            this.$router.replace({"name":"Home"});
                        })
                    })
                    .catch(error => {
                        console.log("add restaurant err: ", error)
                        this.show = false
                        this.$swal("Error", "Failed to Add Restaurant", "error")
                        console.log(error);
                    });
            }
        }

    }
</script>

<style lang="scss" scoped>

</style>