<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
                 <h4><i class="fa fa-bar-chart"></i> Deduct Fund Request </h4>

                    <br> <hr>
                    <form class="form-horizontal style-form" @submit.prevent="onSubmit">

                        <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">User Email: </label>
                        <div class="col-sm-12">
                            <input type="email" v-model="email" class="form-control" disabled>
                        </div>
                        </div>

                        <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">Amount: </label>
                        <div class="col-sm-12">
                            <input type="number" v-model="amount" class="form-control" required>
                        </div>
                        </div>
                        
                        <div class="showback">
                        &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-md btn-danger"><i class="fa fa-plus"></i> Deduct Funds </button>
                        </div>
                    </form>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
    </div>
    <!-- /row -->
    </section>
    </section>
    </div>
</template>


<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';

    export default {

            mounted(){
                let fetchedEmail = this.$route.params.email;
                this.email = fetchedEmail;
            },

            components : {
                'app-default':Default,
                loading
            },

            data(){
                return {
                    show: false,
                    label: 'Loading...',
                    overlay:true,
                    email : '',
                    amount : '',
                }
            },

            methods: {
                

                onSubmit() {
                    // upload file
                    console.log("email: ", this.email);
                    console.log("amount: ", this.amount);
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    console.log("tokenValue: "+tokenValue);
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };    
                    const payload = {"user_email":this.email, "amount":this.amount};
                    console.log("payload: ", payload);
                    this.axios.post(this.$baseUrl+'api/admin/deduct_fund_request', payload, options, {
                    }).then((res) => {
                        console.log(res)
                        this.show = false
                        this.$swal("Success", "Fund Deduction Request Created Successfully", "success").then(() => {
                            this.$router.replace({"name":"Users"});
                        })
                    })
                    .catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Create Request", "error")
                        console.log(error);
                    });
                }  
            }
        
    }

</script>

<style lang="scss" scoped>

</style>