<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
            <h4><i class="fa fa-bar-chart"></i> All Users Logs  </h4>
              <table class="table table-striped table-advance table-hover">
                <thead> 
                  <tr>
                    <th> S/N </th>
                    <th>First Name </th>
                    <th>Last Name </th>
                    <th> Email </th>
                    <th> Description </th>
                    <th> Date | Time </th>
                </tr>
                </thead>
                <tbody>

                    <tr v-if="allUsers.length <= 0">
                        <td colspan=100%> <p class="text-center text-primary"> No User Logs available yet. </p> </td>
                    </tr>

                    <tr v-for="user in allUsers" :key="user.id">
                    <td> {{user.id}} </td>
                    <td> {{user.first_name}} </td>
                    <td> {{user.last_name}} </td>
                    <td> {{user.email}} </td>
                    <td> {{user.description}} </td>
                    <td v-if="user.created_at == null"> No Date and Time </td>
                    <td v-else> {{user.created_at.substring(0,10)}} |  {{user.created_at.substring(11,16)}} </td>
                    
                    </tr> 


                </tbody>
              </table>

              <div class="center">
                <paginate
                    :page-count="totalPages"
                    :page-range="9"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item page-link'"
                    :class="'pagination'"
                    :active-class="'my-active'"
                    :v-model="currentPage"
                    >
                    <span slot="prevContent">Changed previous button</span>
                    <span slot="nextContent">Changed next button</span>
                    <span slot="breakViewContent">
                    <svg width="16" height="4" viewBox="0 0 16 4">
                        <circle fill="#999999" cx="2" cy="2" r="2" />
                        <circle fill="#999999" cx="8" cy="2" r="2" />
                        <circle fill="#999999" cx="14" cy="2" r="2" />
                    </svg>
                    </span>
                </paginate>
            </div>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
        </div>
        <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';
import Paginate from 'vuejs-paginate';


    export default {

        mounted() {
            let adminRole = localStorage.getItem('admin_role');
            if (adminRole.toLowerCase() == 'superadmin') {
                this.getUsers(this.$baseUrl+'api/admin/logs/user');
            } else {
                this.$router.push('/home');
            }
        },

        methods : {
            clickCallback (pageNum)  {
                this.currentPage = pageNum;
                console.log("currentPage is: ", this.currentPage);
                this.getUsers(this.$baseUrl+'api/admin/logs/user?page='+pageNum);
            },
            getUsers(url) {
                    //MARK
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(url, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        this.allUsers = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.perPage = response.data.per_page;
                        this.totalPages = response.data.last_page;
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to get users", "error")
                        console.log(error);
                    });
            }

        },
            components : {
                'app-default':Default,
                loading,
                'paginate':Paginate
            },

            data(){
                return {
                    show: false,
                    label: 'Fetching All Users...',
                    overlay:true,
                    allUsers : [],
                    baseImageUrl:this.$storageUrl,
                    currentPage: 1,
                    perPage:10,
                    totalPages:10
                }
            },
        
    }

</script>


<style lang="css">
.center {
    margin: auto;
    width: 72%;
    padding: 0px;
  }

.pagination li.my-active {
    background-color: dodgerblue;
    color: dodgerblue;
}

.pagination li.my-active a {
    background-color: dodgerblue;
    color: white;
}

.pagination a {
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid #ddd;
    background-color: white;
  }
  
  .pagination li {
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      border: 1px solid #ddd;
      color: rgb(9, 44, 244);
      background-color: white;
      font-size: 1em;
    }

    
.pagination  a:hover:not(.active) {background-color: rgb(247, 4, 4);}
.pagination  li:hover:not(.active) {background-color: rgb(255, 0, 0);}
</style> 