<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
                 <h4><i class="fa fa-bar-chart"></i> Edit Vendor Email </h4>

                    <br> <hr>
                    <form class="form-horizontal style-form" @submit.prevent="onSubmit">

                        <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">Vendor Email: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="email" class="form-control" required>
                        </div>
                        </div>
                        <div class="showback">
                        &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-md btn-secondary"><i class="fa fa-plus"></i> Edit Vendor Email </button>
                        </div>


                    </form>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
    </div>
    <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>


<script>


import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';

    export default {

            mounted(){
                let id = this.$route.params.id;
                this.getVendor(id);
            },

            components : {
                'app-default':Default,
                loading
            },

            data(){
                return {
                    show: false,
                    label: 'Loading...',
                    overlay:true,
                    FILE: null,
                    email : '',
                    id : '',
                }
            },

            methods: {

                getVendor(id){
                      //MARK
                    console.log("id is: "+id);
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(this.$baseUrl+'api/admin/vendor/find_by_id/'+id, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        this.email = response.data.vendor_email;
                        this.id = response.data.id
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load", "error")
                        console.log(error);
                    });
                },
                
                onFileUpload (event) {
                    this.FILE = event.target.files[0]
                },

                onSubmit() {
                    // upload file
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    const formData = new FormData()
                    formData.append('id', this.id)
                    formData.append('vendor_email', this.email)
                    this.axios.post(this.$baseUrl+'api/admin/update_vendor_email', formData, options, {
                    }).then((res) => {
                        console.log(res)
                        this.show = false
                        this.$swal("Success", "Vendor Email Edited Successfully", "success").then(() => {
                            this.$router.replace({"name":"Vendors"});
                        })
                    })
                    .catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Edit Vendor", "error")
                        console.log(error);
                    });
                }  
            }
        
    }

</script>

<style lang="scss" scoped>

</style>