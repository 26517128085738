<template>
    <div>
        <Default />
        <body>
            <loading 
            :show="show"
            :label="label"
            :overlay="overlay"
            ></loading>
              <section id="main-content">
            <section class="wrapper site-min-height">
            <!-- row -->
            <div class="row mt">
                  <div class="col-md-12">
                    <div class="content-panel">
                    <h4>
                    <i class="fa fa-bar-chart"></i> 
                    All Restaurants Listing 
                    <router-link  v-if="adminRole==isSuperAdmin"   to="/restaurant/add" class="btn btn-md btn-primary float-right" style="margin-right:10px" >Add New Restaurant</router-link>
                    </h4>
                    <br>
                      <table class="table table-striped table-advance table-hover">
                      
                        <thead>
                          <tr>
                            <th> S/N </th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Location</th>
                            <th>Description</th>
                            <th>Rating</th>
                            <th>Phone Number</th>
                            <th>Image</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in restaurantArray" :key="index">
                            <td> {{item.id}} </td>
                            <td> {{item.name}} </td>
                            <td> {{item.address}} </td>
                            <td> {{item.location}} </td>
                            <td> {{item.description}} </td>
                            <td> {{item.rating}} </td>
                            <td> {{item.phone_number}} </td>
                            <td> <img :src="imgLink+item.restaurant_image" alt="Restaurant image" class="img-responsive img-thumbnail" style="height: 150px; width: 150px;"></td>
                            <td> 
                            <br><br>
                            <router-link class="btn btn-md btn-primary" :to="viewRestaurantLink+item.id"> View </router-link> 
                            <br><br>
                            <router-link class="btn btn-md btn-warning" :to="editRestaurantLink+item.id" > Edit </router-link> 
                            <br><br>
                            <button @click="disableRestaurant(item.id)" v-if="item.disabled=='0'" class="btn btn-md btn-danger"  href="javascript:;">Disable</button> 
                            <button @click="enableRestaurant(item.id)" v-if="item.disabled=='1'" class="btn btn-md btn-success"  href="javascript:;">Enable</button> 
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="center">
                        <paginate
                            :page-count="totalPages"
                            :page-range="9"
                            :margin-pages="1"
                            :click-handler="clickCallback"
                            :prev-text="'Prev'"
                            :next-text="'Next'"
                            :container-class="'pagination'"
                            :page-class="'page-item page-link'"
                            :class="'pagination'"
                            :active-class="'my-active'"
                            :v-model="currentPage"
                            >
                            <span slot="prevContent">Changed previous button</span>
                            <span slot="nextContent">Changed next button</span>
                            <span slot="breakViewContent">
                            <svg width="16" height="4" viewBox="0 0 16 4">
                                <circle fill="#999999" cx="2" cy="2" r="2" />
                                <circle fill="#999999" cx="8" cy="2" r="2" />
                                <circle fill="#999999" cx="14" cy="2" r="2" />
                            </svg>
                            </span>
                        </paginate>
                    </div>
                    </div>
                    <!-- /content-panel -->
                  </div>
                  <!-- /col-md-12 -->
                </div>
                <!-- /row -->
            
            
            </section>
              </section>
        </body>
    </div>
</template>

<script>
    import Default from './Template/Default.vue'
    import loading from 'vue-full-loading'
    import Paginate from 'vuejs-paginate'
  
  export default {
      components : {Default, loading, 'paginate':Paginate},

      data(){
        return {
          restaurantArray : [],
          imgLink: this.$storageUrl + 'restaurant_images/',
          editRestaurantLink: '/edit/restaurant/',
          viewRestaurantLink: '/restaurant/',
          show: false,
          label: 'Fetching Restaurants...',
          overlay:true,
          currentPage: 1,
                    perPage:10,
                    totalPages:10
        }
      },

      mounted() {
        this.getAllRestaurants(this.$baseUrl+'api/admin/restaurant/get_all');
      },

      methods : {
        clickCallback (pageNum)  {
                this.currentPage = pageNum;
                console.log("currentPage is: ", this.currentPage);
                this.getAllRestaurants(this.$baseUrl+'api/admin/restaurant/get_all?page='+pageNum);
            },
        getAllRestaurants(url){
          this.show = true;
          let bearerToken = localStorage.getItem('delivery_app_token');
          const options = {
              headers: { Authorization: `Bearer ${bearerToken}` }
          };
          this.axios.get(url, options).then((response) => {
              this.show = false;
              console.log(response.data);
              this.restaurantArray = response.data.data;

              this.currentPage = response.data.current_page;
              this.perPage = response.data.per_page;
              this.totalPages = response.data.last_page;
          }
          )
        },

        //confirmDisableRestaurant
        disableRestaurant(id) {

          if (confirm("Do you want to disable this restaurant") == true) {
            //call disable restaurant method - passing the ID
            // disableRestaurant(id)
          } else {
            //Do nothing
          }

          this.show = true
          let token = localStorage.getItem('delivery_app_token');
          const tokenValue = "Bearer "+token;
          const options = {
              headers: {'Authorization': tokenValue}
          };
          this.axios.post(this.$baseUrl+'api/admin/restaurant/disable/'+id, {}, options, {
          }).then((res) => {
              console.log(res)
              this.show = false
              this.$swal("Success", "Restaurant Disabled Successfully", "success").then(() => {
                this.getAllRestaurants(this.$baseUrl+'api/admin/restaurant/get_all');
              })
          })
          .catch(error => {
              this.show = false
              this.$swal("Error", "Failed to Disable restaurant", "error")
              console.log(error);
          });
        },
        enableRestaurant(id) {
            this.show = true
            let token = localStorage.getItem('delivery_app_token');
            const tokenValue = "Bearer "+token;
            const options = {
                headers: {'Authorization': tokenValue}
            };
            this.axios.post(this.$baseUrl+'api/admin/restaurant/restore/'+id, {}, options, {
            }).then((res) => {
                console.log(res)
                this.show = false
                this.$swal("Success", "Restaurant Enabled Successfully", "success").then(() => {
                  this.getAllRestaurants(this.$baseUrl+'api/admin/restaurant/get_all');
                })
            })
            .catch(error => {
                this.show = false
                this.$swal("Error", "Failed to Enable restaurant", "error")
                console.log(error);
            });
          },
      },
      

      computed : {
       
       adminRole(){
         let adminRole = localStorage.getItem('admin_role');
         return adminRole.toLowerCase();
       },

       isSuperAdmin(){
         return 'superadmin';
       }

     }
     
      
      }



</script>

<style lang="css">
.center {
    margin: auto;
    width: 72%;
    padding: 0px;
  }

.pagination li.my-active {
    background-color: dodgerblue;
    color: dodgerblue;
}

.pagination li.my-active a {
    background-color: dodgerblue;
    color: white;
}

.pagination a {
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid #ddd;
    background-color: white;
  }
  
  .pagination li {
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      border: 1px solid #ddd;
      color: rgb(9, 44, 244);
      background-color: white;
      font-size: 1em;
    }

    
.pagination  a:hover:not(.active) {background-color: rgb(247, 4, 4);}
.pagination  li:hover:not(.active) {background-color: rgb(255, 0, 0);}
</style> 