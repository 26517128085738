<template>
    <header class="header black-bg">
      <div class="sidebar-toggle-box">
        <div class="fa fa-bars tooltips" data-placement="right" data-original-title="Toggle Navigation"></div>
      </div>
      <!--logo start-->
      <router-link to="/home" class="logo"><b>{{appName}}</b></router-link>
      <!--logo end-->
      <div class="top-menu">
        <ul class="nav pull-right top-menu">
          
          <li>
          <br>
          <a class="btn btn-danger" style="color:white" @click="handleLogout">Logout</a></li>
        </ul>
      </div>
    </header>
</template>

<script>
    export default {
        
        data () {
            return {
                appName : this.$appName
            }
        },

        methods : {
            handleLogout(){
                localStorage.clear();
                this.$router.push('/');
            }
        }

    }
</script>

<style lang="scss" scoped>

</style>