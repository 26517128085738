<template>
    <div>
        <app-default></app-default>
        <loading 
        :show="show"
        :label="label"
        :overlay="overlay"
        ></loading>
    <!--main content start-->
    <!--main content start-->
    <!--main content start-->
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
            <h4><i class="fa fa-bar-chart"></i> All Dispatch Orders 
            <button @click="allRides" class="btn btn-md btn-info" href="javascript:;">All</button>  &nbsp;&nbsp;
            <button @click="pendingRides" class="btn btn-md btn-primary" href="javascript:;">Pending</button> &nbsp;&nbsp;
            <button @click="runningRides" class="btn btn-md btn-warning"  href="javascript:;">Running</button> &nbsp;&nbsp;
            <button @click="cancelledRides" class="btn btn-md btn-dark"  href="javascript:;">User Cancelled</button>   &nbsp;&nbsp;
            <button @click="completedRides" class="btn btn-md btn-success"  href="javascript:;">Completed</button> &nbsp;&nbsp;
            <button @click="declinedRides" class="btn btn-md btn-danger"  href="javascript:;">Declined</button> 
            </h4><br>
              <table class="table table-striped table-advance table-hover">
                <thead> 
                  <tr>
                    <th> S/N </th>
                    <th>Sender Name / Phone  </th>
                    <th> Receiver Name / Phone </th>
                    <th> Pickup </th>
                    <th> Drop off </th>
                    <th> Item </th>
                    <th> Message </th>
                    <th> Quantity </th>
                    <th> Price </th>
                    <th> Type (Delivery) </th>
                    <th> Status </th>
                    <th> Date </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>

                    <tr v-if="ridesArray.length <= 0">
                        <td colspan=100%> <p class="text-center text-primary"> No Ride Order has been added </p> </td>
                    </tr>

                    <tr v-for="ride in ridesArray" :key="ride.id">
                    <td> {{ride.id}} </td>
                    <td> {{ride.sender_name}} ({{ride.sender_phone}}) </td>
                    <td> {{ride.receiver_name}} ({{ride.receiver_phone}}) </td>
                    <td> {{ride.pickup}} </td>
                    <td> {{ride.destination}} </td>
                    <td> {{ride.item}} </td>
                     <td> {{ride.note}} </td>
                     <td> {{ride.quantity}} </td>
                     <td> &#8358;{{Number(Number(ride.price).toFixed(2)).toLocaleString()}} </td>
                     <td> {{ride.delivery_type}} ({{ride.delivery_method}})</td>
                     
                     <td v-if="ride.cancel_request==1 && ride.status=='PENDING'"> <span class="badge badge-dark">CANCELLATION REQUESTED</span> </td>
                     <td v-else> 
                            <span v-if="ride.status=='PENDING'" class="badge badge-primary">Pending</span>
                            <span v-if="ride.status=='PROCESSING'" class="badge badge-warning">Running</span>
                            <span v-if="ride.status=='COMPLETED'" class="badge badge-success">Completed</span>
                            <span v-if="ride.status=='DECLINED'" class="badge badge-danger">Declined</span>
                     </td>
                     <td> {{ride.created_at.substring(0,10)}} </td>
                    <td>  
                        <span v-if="ride.status!='COMPLETED' && ride.status!='DECLINED'">

                            <a v-if="ride.status=='PROCESSING'" class="btn btn-secondary btn-md" style="color:white" @click="viewRider(ride.rider_id, ride.id+'|'+ride.delivery_type)">View Rider</a> <span v-if="ride.status=='PROCESSING'" > <br><br> </span>
                            <a v-if="ride.status!='PROCESSING'" class="btn btn-primary btn-md" style="color:white" @click="assignRider(ride.id+'|'+ride.delivery_type)">Choose Rider</a> <span v-if="ride.status!='PROCESSING'" > <br><br> </span>


                            <!-- <a v-if="ride.status != 'PENDING'" class="btn btn-dark btn-md" style="color:white" @click="updateStatus(ride.id)">Update Order</a>  <span v-if="ride.status != 'PENDING'"> <br><br> </span> -->
                            <a v-if="ride.status != 'PENDING'" class="btn btn-success btn-md" style="color:white" @click="confirmStatus(ride.id, 1)">Confirm Order</a>  <span v-if="ride.status != 'PENDING'"> <br><br> </span>
                            <a class="btn btn-danger btn-md" style="color:white" @click="confirmStatus(ride.id, 0)">Decline Order</a>
                        </span>
                        <a v-if="ride.status=='COMPLETED'" class="btn btn-secondary btn-md" style="color:white" @click="viewCompletedRider(ride.rider_id)">View Rider</a> <span v-if="ride.status=='COMPLETED'"> <br><br> </span>
                    </td>
                    </tr> 
              
                </tbody>
              </table>

              <div class="center">
                <paginate
                    :page-count="totalPages"
                    :page-range="9"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item page-link'"
                    :class="'pagination'"
                    :active-class="'my-active'"
                    :v-model="currentPage"
                    >
                    <span slot="prevContent">Changed previous button</span>
                    <span slot="nextContent">Changed next button</span>
                    <span slot="breakViewContent">
                    <svg width="16" height="4" viewBox="0 0 16 4">
                        <circle fill="#999999" cx="2" cy="2" r="2" />
                        <circle fill="#999999" cx="8" cy="2" r="2" />
                        <circle fill="#999999" cx="14" cy="2" r="2" />
                    </svg>
                    </span>
                </paginate>
              </div>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
        </div>
        <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>
import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';
import Paginate from 'vuejs-paginate'

    export default {

            mounted(){
                this.getAllRestaurants(this.$baseUrl+'api/admin/order/rider/get_all');
            },

            components : {
                'app-default':Default,
                loading,
                'paginate':Paginate
            },

            data(){
                return {
                    altRidesArray : [],
                    ridesArray : [],
                    show: false,
                    label: 'Loading...',
                    overlay:true,
                    currentPage: 1,
                    perPage:10,
                    totalPages:10
                }
            },

            methods : {


                assignRider(id){
                    this.$router.push('/assignDispatch/'+id);
                },


                viewRider(riderId, orderId){

                    var that = this;
                    console.log(riderId);

                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(this.$baseUrl+'api/admin/get_rider_by_id/'+riderId, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        this.$swal({
                        title: "Vendor S/N: "+response.data.vendor_id,
                        text: "Rider Phone & Name: "+response.data.phone_number+' | '+response.data.rider_name,
                        icon: "info",
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Edit Rider',
                        confirmButtonText: 'Okay',
                        }).then(function(result) {
                            if (result.isConfirmed) {
                                //send to server
                                console.log("okay clicked...");    
                            } else {
                                //do nothing
                                that.$router.push('/assignDispatch/'+orderId);
                            }
                        })
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Fetch Rider Information", "error")
                        console.log(error);
                    });

                },

                viewCompletedRider(riderId){
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(this.$baseUrl+'api/admin/get_rider_by_id/'+riderId, options)
                    .then((response) => {
                        console.log(response);
                        this.show = false
                        this.$swal({
                        title: "Vendor S/N: "+response.data.vendor_id,
                        text: "Rider Phone & Name: "+response.data.phone_number+' | '+response.data.rider_name,
                        icon: "info",
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Okay',
                        }).then(function(result) {
                            if (result.isConfirmed) {
                                console.log("okay clicked...");    
                            }
                        })

                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Fetch Rider Information", "error")
                        console.log(error);
                    });
                },

                updateStatus(id){
                     console.log(id);
                     this.$swal("Enter Dispatch Status:", {
                        content: "input"
                     })
                        .then((value) => {
                        this.updateServer(value, id);
                        // this.$swal(`Confirm status as: ${value}`);
                     });
                },


                updateServer(status, id) {
                    console.log("for id: "+ id);
                    console.log("status is: "+ status);
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    const formData = new FormData()
                    formData.append('id', id)
                    formData.append('status', status)
                    this.axios.post(this.$baseUrl+'api/admin/order/rider/update_status', formData, options, {
                    }).then((res) => {
                        console.log(res)
                        this.show = false
                        this.$swal("Success", "Status Updated Successfully", "success")
                        this.getAllRestaurants(this.$baseUrl+'api/admin/order/rider/get_all?page='+this.currentPage);
                    })
                    .catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Update status, try again", "error")
                        console.log(error);
                    });
                },

                confirmStatus(id, status) {

                    console.log(id);

                    const that = this;

                      this.$swal({
                            title: "Are you sure?",
                            text: "Once Dispatch is confirmed or declined, its irreversible",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            cancelButtonText: 'No, go back!',
                            confirmButtonText: 'Yes, I am sure!',
                            }).then(function(result) {
                                if (result.isConfirmed) {
                                    //send to server
                                    that.updateConfirmToServer(id, status);  
                                } else {
                                    //do nothing
                                    console.log("cancelled do nothing...");
                                }
                            })

                },


                updateConfirmToServer(id, status){
                        console.log(id);
                        this.show = true
                        let token = localStorage.getItem('delivery_app_token');
                        const tokenValue = "Bearer "+token;
                        const options = {
                            headers: {'Authorization': tokenValue}
                        };
                        const formData = new FormData()
                        formData.append('id', id)
                        formData.append('isComplete', status)
                        console.log("FormData: \n");
                        console.log(status);
                        this.axios.post(this.$baseUrl+'api/admin/order/rider/update', formData, options, {
                        }).then((res) => {
                            console.log(res)
                            this.show = false
                            this.$swal("Success", "Status Updated Successfully", "success")
                            this.getAllRestaurants(this.$baseUrl+'api/admin/order/rider/get_all?page='+this.currentPage);
                        })
                        .catch(error => {
                            this.show = false
                            this.$swal("Error", "Failed to Update status, try again", "error")
                            console.log(error);
                        });
                },


            clickCallback (pageNum)  {
                this.currentPage = pageNum;
                console.log("currentPage is: ", this.currentPage);
                this.getAllRestaurants(this.$baseUrl+'api/admin/order/rider/get_all?page='+pageNum);
            },

                getAllRestaurants(url){
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(url, options)
                    .then((response) => {
                        this.show = false
                        console.log('getting all dispatch requests...');
                        console.log(response.data);
                        this.altRidesArray = response.data.data;
                        this.ridesArray = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.perPage = response.data.per_page;
                        this.totalPages = response.data.last_page;
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Orders", "error")
                        console.log(error);
                    });
                },
                
                allRides(){
                    this.ridesArray = this.altRidesArray;
                },

                pendingRides(){
                    const rides = this.altRidesArray.filter(ride => ride.status === 'PENDING' && ride.cancel_request==0);
                    this.ridesArray = rides;
                },

                runningRides(){
                    const rides = this.altRidesArray.filter(ride => ride.status === 'PROCESSING');
                    this.ridesArray = rides;
                },

                cancelledRides(){
                    const rides = this.altRidesArray.filter(ride => ride.status === 'PENDING' && ride.cancel_request==1);
                    this.ridesArray = rides;
                },

                completedRides(){
                    const rides = this.altRidesArray.filter(ride => ride.status === 'COMPLETED');
                    this.ridesArray = rides;
                },

                declinedRides(){
                    const rides = this.altRidesArray.filter(ride => ride.status === 'DECLINED');
                    this.ridesArray = rides;
                },
            }


        
    }

</script>

<style lang="scss" scoped>

</style>