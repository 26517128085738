<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
                 <h4><i class="fa fa-bar-chart"></i> Edit Restaurant </h4>

                    <br> <hr>
                    <form class="form-horizontal style-form" @submit.prevent="onSubmit">
                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12  control-label">Restaurant Name: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="name" class="form-control" required>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Restaurant Address </label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control"  v-model="address" required>
                        </div>
                        </div>


                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Restaurant State </label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control" v-model="location" required>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Restaurant Latitude </label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control" v-model="latitude" required>
                        </div>
                        </div>


                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Restaurant Longitude </label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control" v-model="longitude" required>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Restaurant Description </label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control" v-model="description" required>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Restaurant Rating </label>
                        <div class="col-sm-12">
                            <input type="number" class="form-control"  v-model="rating" required>
                        </div>
                        </div>

                        <div class="form-group" style="margin:10px">
                            <label class="col-sm-12 col-sm-12 control-label"> Restaurant Phone </label>
                            <div class="col-sm-12">
                                <input type="number" class="form-control"  v-model="phone_number" required>
                            </div>
                            </div>


                        <div class="form-group" style="margin:10px">
                        <label class="col-sm-12 col-sm-12 control-label"> Restaurant Image </label>
                        <div class="col-sm-12">
                            <input type="file"  @change="onFileUpload" class="form-control" required>
                        </div>
                        </div>

                         <div class="showback">
                        &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-md btn-primary" name="change_password"><i class="fa fa-home"></i> Update Restaurant </button>
                        </div>


                    </form>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
    </div>
    <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>

import Default from '../Template/Default.vue'
import loading from 'vue-full-loading'

export default {

            mounted(){
                let id = this.$route.params.id;
                this.restaurant_id = id;
                this.getRestaurantDetail(id);
            },

            components : {
                'app-default':Default,
                loading
            },

            data(){
                return {
                    show: false,
                    label: 'Loading...',
                    overlay:true,
                    // FILE: '', //null,
                    restaurant : {},
                    name:'',
                    address:'',
                    location:'',
                    description:'',
                    rating:'',
                    longitude: '',
                    latitude: '',
                    restaurant_image:'',
                    phone_number:'',
                    id: 0,
                }
            },

            methods: {

                getRestaurantDetail(id){
                      //MARK
                    console.log("id is: "+id);
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(this.$baseUrl+'api/admin/restaurant/get_by_id/'+id, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        this.restaurant = response.data;
                        this.id  = response.data.id;
                        this.name = response.data.name;
                        this.address = response.data.address;
                        this.location = response.data.location;
                        this.description = response.data.description;
                        this.rating = response.data.rating;
                        this.longitude = response.data.restaurant_long;
                        this.latitude = response.data.restaurant_lat;
                        this.phone_number = response.data.phone_number;

                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Restaurant Info", "error")
                        console.log(error);
                    });
                },
                
                onFileUpload (event) {
                    this.restaurant_image = event.target.files[0]

                console.log('restaurant imgs...');
                console.log('1: ', event.target.files[0]);
                
                },

                onSubmit() {
                    // upload file
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const options = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data'};
                    const formData = new FormData()
                    formData.append('name', this.name)
                    formData.append('address', this.address)
                    formData.append('location', this.location)
                    formData.append('phone_number', this.phone_number)
                    formData.append('description', this.description)
                    formData.append('rating', this.rating)
                    formData.append('restaurant_long', this.longitude)
                    formData.append('restaurant_lat', this.latitude)
                    formData.append('restaurant_image', this.restaurant_image) 
                    formData.append('id', this.id)
                    let url = this.$baseUrl+'api/admin/restaurant/update';
                    this.axios({
                method: "post",
                url: url,
                data: formData,
                headers: options,
                }).then((res) => {
                        console.log(res)
                        this.show = false
                        this.$swal("Success", "Restaurant Edited Successfully", "success").then(() => {
                            this.$router.replace({"name":"Home"});
                        })
                    })
                    .catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Edit Restaurant", "error")
                        console.log(error);
                    });
                }  
            }
        
    }

</script>


<style lang="scss" scoped>

</style>