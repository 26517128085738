<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
                 <h4><i class="fa fa-bar-chart"></i> Edit Admin </h4>

                    <br> <hr>
                    <form class="form-horizontal style-form" @submit.prevent="onSubmit">

                        <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">First Name: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="fname" class="form-control" required>
                        </div>
                        </div>

                        <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">Last Name: </label>
                        <div class="col-sm-12">
                            <input type="text" v-model="lname" class="form-control" required>
                        </div>
                        </div>

                        <div class="showback">
                        &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-md btn-success"><i class="fa fa-user"></i> Update </button>
                        </div>


                    </form>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
    </div>
    <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';

    export default {

            components : {
                'app-default':Default,
                loading
            },

            data(){
                return {
                    show: false,
                    label: 'Editing Admin...',
                    overlay:true,
                    fname: "",
                    lname: ""
                }
            },

            mounted() {
               this.fname = localStorage.getItem('admin_fname');
               this.lname = localStorage.getItem('admin_lname');
            },

            methods: {
                onSubmit() {
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    let params = {"first_name": this.fname, "last_name": this.lname};
                    this.axios.put(this.$baseUrl+'api/admin/update', params, options, {
                    }).then((res) => {
                        console.log(res)
                        this.show = false
                        this.$swal("Success", "Admin Info updated Successfully", "success").then(() => {
                            localStorage.setItem('admin_fname', this.fname);
                            localStorage.setItem('admin_lname', this.lname);
                            this.$router.replace({"name":"Home"});
                        })
                    })
                    .catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Edit Admin", "error")
                        console.log("Error is: ");
                        console.log(error);
                    });
                }  
            }
        
    }

</script>


<style lang="scss" scoped>

</style>