import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Auth/Login.vue'
import Home from '../views/Dashboard/Home.vue'
import AddRestaurant from '../views/Dashboard/Restaurant/AddNewRestaurant.vue'
import EditRestaurant from '../views/Dashboard/Restaurant/EditRestaurant.vue'
import ViewRestaurant from '../views/Dashboard/Restaurant/RestaurantDetail.vue'
import AddFood from '../views/Dashboard/Restaurant/AddFood.vue'
import EditFood from '../views/Dashboard/Restaurant/EditFood.vue'
import FoodOrders from '../views/Dashboard/FoodOrders/FoodOrders.vue'
import AssignRider from '../views/Dashboard/FoodOrders/AssignRider.vue'
import DispatchOrders from '../views/Dashboard/DispatchOrders/DispatchOrders.vue'
import AssignDispatch from '../views/Dashboard/DispatchOrders/AssignDispatch.vue'
import CashRequest from '../views/Dashboard/CashRequests/CashRequests.vue'
import AssignCash from '../views/Dashboard/CashRequests/AssignCash.vue'
import Vendors from '../views/Dashboard/Vendors/Vendors.vue'
import AddRider from '../views/Dashboard/Vendors/AddRider.vue'
import AddVendor from '../views/Dashboard/Vendors/AddVendor.vue'
import EditVendorEmail from '../views/Dashboard/Vendors/EditVendorEmail.vue'
import EditVendorInfo from '../views/Dashboard/Vendors/EditVendorInfo.vue'
import EditVendorPhone from '../views/Dashboard/Vendors/EditVendorPhone.vue'
import Users from '../views/Dashboard/Users/Users.vue'
import Admins from '../views/Dashboard/Admins/Admins.vue'
import AddAdmin from '../views/Dashboard/Admins/AddAdmin.vue'
import AdminLogs from '../views/Dashboard/Admins/AdminLogs.vue'
import AdminTransactions from '../views/Dashboard/Admins/AdminTransactions.vue'
import VendorRiders from '../views/Dashboard/Vendors/VendorRiders.vue'
import LogsUser from '../views/Dashboard/Users/UserLogs.vue'
import VendorRiderRides from '../views/Dashboard/Vendors/VendorRiderRides.vue'
import CreateFundUser from '../views/Dashboard/Users/CreateFundUser.vue'
import DeductFundUser from '../views/Dashboard/Users/DeductFundUser.vue'
import AuthorizeUserLogs from '../views/Dashboard/Users/AuthorizeUserLogs.vue'
import AuthorizeDeductUserLogs from '../views/Dashboard/Users/AuthorizeDeductUserLogs.vue'
import ChangeAdminInfo from '../views/Dashboard/Admins/ChangeAdminInfo.vue'
import ChangePassword from '../views/Dashboard/Admins/ChangePassword.vue'
import Pricing from '../views/Dashboard/Admins/Pricing.vue'
import EditPricing from '../views/Dashboard/Admins/EditPricing.vue'


Vue.use(VueRouter)

const routes = [

  //Authentication
  {
    path: '/',
    name: 'Login',
    component: Login
  },

  //Dashboard - Home
  {
    path: '/home',
    name: 'Home',
    meta: {requiresAuth:true},
    component: Home
  },

  //Restaurants
  {
    path: '/restaurant/add',
    name: 'AddRestaurant',
    meta: {requiresAuth:true},
    component: AddRestaurant
  },

  {
    path: '/edit/restaurant/:id',
    name: 'EditRestaurant',
    meta: {requiresAuth:true},
    component: EditRestaurant
  },

  {
    path: '/restaurant/:id',
    name: 'ViewRestaurant',
    meta: {requiresAuth:true},
    component: ViewRestaurant
  },

  {
    path: '/foods/add/:id',
    name: 'AddFood',
    meta: {requiresAuth:true},
    component: AddFood
  },

  {
    path: '/edit/food/:id',
    name: 'EditFood',
    meta: {requiresAuth:true},
    component: EditFood
  },

  
  
  //Food Orders
  {
    path: '/foodorders',
    name: 'FoodOrders',
    meta: {requiresAuth:true},
    component: FoodOrders
  },

  {
    path: '/assignRider/:id',
    name: 'AssignRider',
    meta: {requiresAuth:true},
    component: AssignRider
  },

  //Dispatch Orders
  {
    path: '/dispatchorders',
    name: 'DispatchOrders',
    meta: {requiresAuth:true},
    component: DispatchOrders
  },

  {
    path: '/assignDispatch/:id',
    name: 'AssignDispatch',
    meta: {requiresAuth:true},
    component: AssignDispatch
  },
  
  
  //Cash Requests
  {
    path: '/cashrequests',
    name: 'CashRequest',
    meta: {requiresAuth:true},
    component: CashRequest
  },

  {
    path: '/assignCash/:id',
    name: 'AssignCash',
    meta: {requiresAuth:true},
    component: AssignCash
  },
  
  // Vendors
  {
    path: '/vendors',
    name: 'Vendors',
    meta: {requiresAuth:true},
    component: Vendors
  },

  {
    path: '/addvendor',
    name: 'AddVendor',
    meta: {requiresAuth:true},
    component: AddVendor
  },

  {
    path: '/addrider/:id',
    name: 'AddRider',
    meta: {requiresAuth:true},
    component: AddRider
  },

  {
    path: '/edit/vendorphone/:id',
    name: 'EditVendorPhone',
    meta: {requiresAuth:true},
    component: EditVendorPhone
  },

  {
    path: '/edit/vendoremail/:id',
    name: 'EditVendorEmail',
    meta: {requiresAuth:true},
    component: EditVendorEmail
  },

  {
    path: '/edit/vendor/:id',
    name: 'EditVendorInfo',
    meta: {requiresAuth:true},
    component: EditVendorInfo
  },
  
  //Users
  {
    path: '/users',
    name: 'Users',
    meta: {requiresAuth:true},
    component: Users
  },


  //Admin
  {
    path: '/admins',
    name: 'Admins',
    meta: {requiresAuth:true},
    component: Admins
  },

  {
    path: '/addadmin',
    name: 'AddAdmin',
    meta: {requiresAuth:true},
    component: AddAdmin
  },

  {
    path: '/logsadmin',
    name: 'AdminLogs',
    meta: {requiresAuth:true},
    component: AdminLogs
  },

  {
    path: '/admintransactions',
    name: 'AdminTransactions',
    meta: {requiresAuth:true},
    component: AdminTransactions
  },

  {
    path: '/vendors/rider/:id',
    name: 'VendorRiders',
    meta: {requiresAuth:true},
    component: VendorRiders
  },

  {
    path: '/vendors/rider/rides/:id',
    name: 'VendorRiderRides',
    meta: {requiresAuth:true},
    component: VendorRiderRides
  },

  {
    path: '/users/fundRequest/:email',
    name: 'CreateFundUser',
    meta: {requiresAuth:true},
    component: CreateFundUser
  },

  {
    path: '/users/deductRequest/:email',
    name: 'DeductFundUser',
    meta: {requiresAuth:true},
    component: DeductFundUser
  },
  

  {
    path: '/logsuser',
    name: 'LogsUser',
    meta: {requiresAuth:true},
    component: LogsUser
  },

  {
    path: '/fundrequestlogs',
    name: 'AuthorizeUserLogs',
    meta: {requiresAuth:true},
    component: AuthorizeUserLogs
  },

  {
    path: '/deductrequestlogs',
    name: 'AuthorizeDeductUserLogs',
    meta: {requiresAuth:true},
    component: AuthorizeDeductUserLogs
  },


  {
    path: '/changeinfo',
    name: 'ChangeAdminInfo',
    meta: {requiresAuth:true},
    component: ChangeAdminInfo
  },


  {
    path: '/changepassword',
    name: 'ChangePassword',
    meta: {requiresAuth:true},
    component: ChangePassword
  },

  {
    path: '/pricing',
    name: 'Pricing',
    meta: {requiresAuth:true},
    component: Pricing
  },

  {
    path: '/edit/pricing/:id',
    name: 'EditPricing',
    meta: {requiresAuth:true},
    component: EditPricing
  },

  
  

  //the others
  { path: "*", component: Login }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach( (to, from, next) => {
  var isUserLoggedIn = localStorage.getItem('isLoggedIn');
  if (to.meta.requiresAuth) {
    if (isUserLoggedIn) {
      next()
    }else{
      next({name:'Login'})
    }
  }else{
    next()
  }
})
export default router
