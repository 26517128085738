<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
                 <h4><i class="fa fa-bar-chart"></i> Assign Rider To Cash Request </h4>

                    <br> <hr>
                    <form class="form-horizontal style-form" @submit.prevent="onSubmit">


                        <div class="form-group">
                        <label class="col-sm-12 col-sm-12  control-label">Select Rider: </label>
                        <div class="col-sm-12">
                            <select v-model="riderId" class="form-control" required>
                            <option v-for="rider in riders" :key="rider.id"> {{ rider.id }} | {{rider.rider_name}} |  {{rider.address}} |  {{rider.type}} |  {{rider.phone_number}} </option>
                            </select>
                        </div>
                        </div>


                        <div class="showback">
                        &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-md btn-success"><i class="fa fa-plus"></i> Assign Rider </button>
                        </div>


                    </form>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
    </div>
    <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>


<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';

    export default {

            mounted(){
                let id = this.$route.params.id;
                this.orderId = id;
                this.fetchAllRiders();
            },

            components : {
                'app-default':Default,
                loading
            },

            data(){
                return {
                    show: false,
                    label: 'Loading...',
                    overlay:true,
                    riderId: '',
                    orderId: '',
                    riders : []
                }
            },

            methods: {
                

                fetchAllRiders() {
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(this.$baseUrl+'api/admin/get_all_riders', options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data.data);
                        this.riders = response.data.data;
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Riders", "error")
                        console.log(error);
                    });
                },

                onSubmit() {
                    // upload file
                    
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };   
                    var riderArray = this.riderId.split("|");
                    this.riderId = riderArray[0];
                    console.log("riderId: " + this.riderId);
                    console.log("orderId: " + this.orderId);
                    
                    const formData = new FormData()
                    formData.append('order_id', this.orderId)
                    formData.append('rider_id', this.riderId)
                    this.axios.post(this.$baseUrl+'api/admin/rider/assign_to_cash_request', formData, options, {
                    }).then((res) => {
                        console.log(res)
                        this.show = false
                        this.$swal("Success", "Rider Assigned To Cash Request Successfully", "success").then(() => {
                            this.$router.replace({"name":"CashRequest"});
                        })
                    })
                    .catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Assign Rider", "error")
                        console.log(error);
                    });
                }  
            }
        
    }

</script>

<style lang="scss" scoped>

</style>