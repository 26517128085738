<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
            <h4><i class="fa fa-bar-chart"></i> Deduct Request Logs  </h4>

              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link"  :class="currentView==1?'active':''" aria-current="page"  @click="viewRequests(1)">All</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"  :class="currentView==2?'active':''" @click="viewRequests(2)">Pending</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"   :class="currentView==3?'active':''" @click="viewRequests(3)">Approved</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link"   :class="currentView==4?'active':''" @click="viewRequests(4)">Declined</a>
                  </li>
              </ul>

              <table class="table table-striped table-advance table-hover">
                <thead> 
                  <tr>
                    <th> S/N </th>
                    <th> Email </th>
                    <th> Amount </th>
                    <th>Status</th>
                    <th> Date </th>
                    <th> Time </th>
                    <th> Action </th>
                </tr>
                </thead>
                <tbody>

                    <tr v-if="allUsers.length <= 0">
                        <td colspan=100%> <p class="text-center text-primary"> No Deduct Requests available yet. </p> </td>
                    </tr>

                    <tr v-for="user in allUsers" :key="user.id">
                    <td> {{user.id}} </td>
                    <td> {{user.user_email}} </td>
                    <td> &#8358;{{Number(Number(user.amount).toFixed(2)).toLocaleString()}} </td>
                    <td> 
                        <span v-if="user.status=='PENDING'" class="badge badge-primary">Pending</span>
                        <span v-if="user.status=='PROCESSING'" class="badge badge-warning">Running</span>
                        <span v-if="user.status=='APPROVED'" class="badge badge-success">APPROVED</span>
                        <span v-if="user.status=='DECLINED'" class="badge badge-danger">Declined</span>
                    </td>
                    <td> {{user.created_at.substring(0,10)}} </td>
                    <td> {{user.created_at.substring(11,16)}} </td>
                    <td> 
                        <br><br>
                        <a  v-if="user.status=='PENDING'" class="btn btn-primary btn-md" style="color:white" @click="approveRequest(user.id)">Accept</a>
                        <br><br>
                        <a v-if="user.status=='PENDING'" class="btn btn-danger btn-md" style="color:white" @click="declineRequest(user.id)">Decline</a>
                        
                        </td>
                    
                    </tr> 
              
                </tbody>



              <div class="center">
                <paginate
                    :page-count="totalPages"
                    :page-range="9"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item page-link'"
                    :class="'pagination'"
                    :active-class="'my-active'"
                    :v-model="currentPage"
                    >
                    <span slot="prevContent">Changed previous button</span>
                    <span slot="nextContent">Changed next button</span>
                    <span slot="breakViewContent">
                    <svg width="16" height="4" viewBox="0 0 16 4">
                        <circle fill="#999999" cx="2" cy="2" r="2" />
                        <circle fill="#999999" cx="8" cy="2" r="2" />
                        <circle fill="#999999" cx="14" cy="2" r="2" />
                    </svg>
                    </span>
                </paginate>
              </div>


              </table>
            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
        </div>
        <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';
import Paginate from 'vuejs-paginate';

    export default {

        mounted() {
            let adminRole = localStorage.getItem('admin_role');
            if (adminRole.toLowerCase() == 'superadmin') {
                this.getUsers(this.$baseUrl+'api/admin/get_all_deduction_request');
            } else {
                this.$router.push('/home');
            }
        },

        methods : {

            clickCallback (pageNum)  {
                this.currentPage = pageNum;
                console.log("currentPage is: ", this.currentPage);
                this.getUsers(this.$baseUrl+'api/admin/get_all_deduction_request?page='+pageNum);
            },

            viewRequests(id) {
                if (id == 1) {
                    this.currentView = 1;
                    this.allUsers = this.allFliteredUsers;
                } else if (id == 2) {
                    this.currentView = 2;
                    this.allUsers = this.allFliteredUsers.filter(c => c.status == "PENDING");
                }else if (id == 3) {
                    this.currentView = 3;
                    this.allUsers = this.allFliteredUsers.filter(c => c.status == "APPROVED");
                }else{
                    this.currentView = 4;
                    this.allUsers = this.allFliteredUsers.filter(c => c.status == "DECLINED");
                }
            },

            approveRequest(id) {
                console.log("id: ", id);
                this.onSubmit(id, "1");
            },
            declineRequest(id) {
                console.log("id: ", id);
                this.onSubmit(id, "2");
            },
            onSubmit(id, status) {
                    // upload file
                    console.log("id: ", id);
                    console.log("status: ", status);
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    console.log("tokenValue: "+tokenValue);
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };    
                    const payload = {"id":id, "status":status};
                    console.log("payload: ", payload);
                    this.axios.post(this.$baseUrl+'api/admin/approve_or_decline_deduct_fund', payload, options, {
                    }).then((res) => {
                        console.log(res)
                        this.show = false
                        let msg = status == "1" ? "Fund Deduction Request Approved Successfully" : "Fund Deduction Request Declined Successfully";
                        this.$swal("Success", msg, "success").then(() => {
                            this.clickCallback(this.currentPage);
                        })
                    })
                    .catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Authorize Request", "error")
                        console.log(error);
                    });
                },
            getUsers(url) {
                    //MARK
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(url, options)
                    .then((response) => {
                        this.currentView = 1;
                        this.show = false
                        console.log(response.data);
                        this.allUsers =  response.data.data;
                        this.allFliteredUsers = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.perPage = response.data.per_page;
                        this.totalPages = response.data.last_page;
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to get users", "error")
                        console.log(error);
                    });
            }

        },
            components : {
                'app-default':Default,
                loading,
                'paginate':Paginate
            },

            data(){
                return {
                    show: false,
                    label: 'Fetching All Users...',
                    overlay:true,
                    allUsers : [],
                    allFliteredUsers : [],
                    baseImageUrl: this.$storageUrl,
                    currentView: 1,
                    currentPage: 1,
                    perPage:10,
                    totalPages:10
                }
            },
        
    }

</script>


<style lang="scss" scoped>

</style>