<template>
    <div>
    <app-default></app-default>
    <loading 
    :show="show"
    :label="label"
    :overlay="overlay"
    ></loading>
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
            <h4><i class="fa fa-bar-chart"></i> All Users  <h5 class="text text-primary float-right">Total Users: {{allUsers.length}} &nbsp;&nbsp;&nbsp; </h5>  </h4>
              <table class="table table-striped table-advance table-hover">
                <thead> 
                  <tr>
                    <th> S/N </th>
                    <th>First Name </th>
                    <th>Last Name </th>
                    <th> Phone </th>
                    <th> Email </th>
                    <th> Balance </th>
                    <th> Date Of Birth </th>
                    <th> Date Joined </th>
                    <th> Last Login </th>
                    <th> Picture </th>
                    <th> Actions </th>
                </tr>
                </thead>
                <tbody>

                    <tr v-if="allUsers.length <= 0">
                        <td colspan=100%> <p class="text-center text-primary"> No User has registered </p> </td>
                    </tr>

                    <tr v-for="user in allUsers" :key="user.id">
                    <td> {{user.id}} </td>
                    <td> {{user.first_name}} </td>
                    <td> {{user.last_name}} </td>
                    <td> {{user.phone_number}} </td>
                    <td> {{user.email}} </td>
                    <td> &#8358;{{Number(Number(user.balance).toFixed(2)).toLocaleString()}} </td>
                    <td> {{user.dob}} </td>
                    <td> {{user.created_at.substring(0,10)}} </td>
                    <td> {{user.last_login_time != null ? user.last_login_time.substring(0,10) : user.updated_at.substring(0,10)}} </td>
                    <td v-if="user.profile_image == null"> <img class="img-responsive img-thumbnail" src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png" style="height: 150px; width: 150px" alt="user image"></td>
                    <td v-else> <img class="img-responsive img-thumbnail" :src=" baseImageUrl+ 'profile_images/' + user.profile_image" style="height: 150px; width: 150px" alt="user image"> </td>
                    <td> 
                        <br><br>
                        <a class="btn btn-primary btn-md" style="color:white" @click="createFundRequest(user.email)">Create Fund Request</a>
                        <br><br>
                        <a v-if="adminRole==isSuperAdmin" class="btn btn-danger btn-md" style="color:white" @click="deductFundRequest(user.email)">Deduct Fund Request</a>
                        
                        </td>
                    </tr> 
              
                </tbody>
              </table>

              <div class="center">
                <paginate
                    :page-count="totalPages"
                    :page-range="9"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item page-link'"
                    :class="'pagination'"
                    :active-class="'my-active'"
                    :v-model="currentPage"
                    >
                    <span slot="prevContent">Changed previous button</span>
                    <span slot="nextContent">Changed next button</span>
                    <span slot="breakViewContent">
                    <svg width="16" height="4" viewBox="0 0 16 4">
                        <circle fill="#999999" cx="2" cy="2" r="2" />
                        <circle fill="#999999" cx="8" cy="2" r="2" />
                        <circle fill="#999999" cx="14" cy="2" r="2" />
                    </svg>
                    </span>
                </paginate>
            </div>

            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
        </div>
        <!-- /row -->
    
    
    </section>
    </section>
    </div>
</template>

<script>

import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';
import Paginate from 'vuejs-paginate';

    export default {
        computed : {
          adminRole(){
            let adminRole = localStorage.getItem('admin_role');
            return adminRole.toLowerCase();
          },

          isSuperAdmin(){
            return 'superadmin';
          }

        },
        mounted() {
            this.getUsers(this.$baseUrl+'api/admin/get_all_users');
            // let adminRole = localStorage.getItem('admin_role');
            // if (adminRole.toLowerCase() == 'superadmin') {
            // this.getUsers(this.$baseUrl+'api/admin/get_all_users');
            // } else {
            //     this.$router.push('/home');
            // }
        },

        methods : {
            createFundRequest(email) {
                console.log(email);
                this.$router.push('/users/fundRequest/'+email);
            },
            deductFundRequest(email) {
                console.log(email);
                this.$router.push('/users/deductRequest/'+email);
            },
            clickCallback (pageNum)  {
                this.currentPage = pageNum;
                console.log("currentPage is: ", this.currentPage);
                this.getUsers(this.$baseUrl+'api/admin/get_all_users?page='+pageNum);
            },

            getUsers(url) {
                    //MARK
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(url, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        this.allUsers = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.perPage = response.data.per_page;
                        this.totalPages = response.data.last_page;
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to get users", "error")
                        console.log(error);
                    });
            }

        },
            components : {
                'app-default':Default,
                loading,
                'paginate':Paginate
            },

            data(){
                return {
                    show: false,
                    label: 'Fetching All Users...',
                    overlay:true,
                    allUsers : [],
                    baseImageUrl:this.$storageUrl,
                    currentPage: 1,
                    perPage:10,
                    totalPages:10
                }
            },
        
    }

</script>


<style lang="scss" scoped>

</style>