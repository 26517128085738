<template>
    <div>
        <app-default></app-default>
        <loading 
        :show="show"
        :label="label"
        :overlay="overlay"
        ></loading>
    <!--main content start-->
    <!--main content start-->
    <!--main content start-->
    <section id="main-content">
    <section class="wrapper site-min-height">
        
     <!-- row -->
     <div class="row mt">
          <div class="col-md-12">
            <div class="content-panel">
            <h4><i class="fa fa-bar-chart"></i> All Food Orders 
                <button @click="allFoods" class="btn btn-md btn-info" href="javascript:;">All</button>  &nbsp;&nbsp;
                <button @click="pendingFoods" class="btn btn-md btn-primary" href="javascript:;">Pending</button> &nbsp;&nbsp;
                <button @click="runningFoods" class="btn btn-md btn-warning"  href="javascript:;">Running</button> &nbsp;&nbsp;
                <button @click="cancelledFoods" class="btn btn-md btn-dark"  href="javascript:;">User Cancelled</button>   &nbsp;&nbsp;
                <button @click="completedFoods" class="btn btn-md btn-success"  href="javascript:;">Completed</button> &nbsp;&nbsp;
                <button @click="declinedFoods" class="btn btn-md btn-danger"  href="javascript:;">Declined</button> 
             </h4><br>
              <table class="table table-striped table-advance table-hover">
                <thead>
                
                  <tr>
                    <th> S/N </th>
                    <th>Receiver Name</th>
                    <th>Receiver Phone</th>
                    <th>Receiver Address</th>
                    <th>Restaurant | Food  | QTY </th>
                    <th> Price </th>
                    <th> Delivery Method | Amount </th>
                    <th>Payment Method</th>
                    <th> Date of Order </th>
                    <th> Status </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>

                    <tr v-if="foodArray.length <= 0">
                        <td colspan=100%> <p class="text-center text-primary"> No Food orders has been requested </p> </td>
                    </tr>

                    <tr v-for="food in foodArray" :key="food.id">
                    <td> {{food.id}} </td>
                    <td> {{food.customer_name}} </td>
                    <td> {{food.customer_phone}} </td>
                    <td> {{food.customer_address}} </td>
                    <td> 
                    <a  class="btn btn-primary btn-md" style="color:white" @click="getOrderedFoodsInfo(food.orders, food.delivery_amount, food.amount)">View Order</a>
                    <br><br>
                    <a  class="btn btn-success btn-md" style="color:white" @click="getSenderInfo(food.user_id)">Sender Info</a>

                    
                    <!-- <span style="white-space: pre-wrap; word-wrap: break-word; font-family: inherit;"> {{ getRestaurantsNames(food.orders) }} </span>  -->
                    </td>
                    <td> &#8358;{{Number(Number(food.amount).toFixed(2)).toLocaleString()}} </td>
                    <td> {{food.delivery_method}} | &#8358;{{Number(Number(food.delivery_amount).toFixed(2)).toLocaleString()}} </td>
                    <td> {{ food.payment_method }} </td>
                    <td> {{ food.createdAt }} </td>
                     <td v-if="food.cancel_request==1 && food.status=='PENDING'"><span class="badge badge-dark">CANCELLATION REQUESTED</span>  </td>
                     <td v-else> 
                            <span v-if="food.status=='PENDING'" class="badge badge-primary">Pending</span>
                            <span v-if="food.status=='PROCESSING'" class="badge badge-warning">Running</span>
                            <span v-if="food.status=='COMPLETED'" class="badge badge-success">Completed</span>
                            <span v-if="food.status=='DECLINED'" class="badge badge-danger">Declined</span>
                     </td>
                    <td>
                        <span v-if="food.status!='COMPLETED' && food.status!='DECLINED'">
                        <a v-if="food.status=='PROCESSING'" class="btn btn-secondary btn-md" style="color:white" @click="viewRider(food.rider_id, food.id)">View Rider</a> <span v-if="food.status=='PROCESSING'"> <br><br> </span>
                        <a v-if="food.status!='PROCESSING'" class="btn btn-primary btn-md" style="color:white" @click="assignRider(food.id)">Choose Rider</a> <span v-if="food.status!='PROCESSING'"> <br><br> </span>
                        <!-- <a v-if="food.status != 'PENDING'" class="btn btn-dark btn-md" style="color:white" @click="updateFoodOrder(food.id)">Update Order</a>  <span v-if="food.status != 'PENDING'"> <br><br> </span> -->
                        <a v-if="food.status != 'PENDING'" class="btn btn-success btn-md" style="color:white" @click="confirmStatus(food.id, 1)">Confirm Order</a>  <span v-if="food.status != 'PENDING'"> <br><br> </span>
                        <a class="btn btn-danger btn-md" style="color:white" @click="confirmStatus(food.id, 0)">Decline Order</a>
                        <br><br>
                        <a  class="btn btn-dark btn-md" style="color:white" @click="getRestaurantDetails(food.orders[0].id)">View Restaurant</a> <br><br>
                    </span>
                        <a v-if="food.status=='COMPLETED'" class="btn btn-secondary btn-md" style="color:white" @click="viewCompletedRider(food.rider_id)">View Rider</a> <span v-if="food.status=='COMPLETED'"> <br><br> </span>
                        
                        
                    </td>
                    </tr> 
              
                </tbody>
              </table>




              <div class="center">
                <paginate
                    :page-count="totalPages"
                    :page-range="9"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item page-link'"
                    :class="'pagination'"
                    :active-class="'my-active'"
                    :v-model="currentPage"
                    >
                    <span slot="prevContent">Changed previous button</span>
                    <span slot="nextContent">Changed next button</span>
                    <span slot="breakViewContent">
                    <svg width="16" height="4" viewBox="0 0 16 4">
                        <circle fill="#999999" cx="2" cy="2" r="2" />
                        <circle fill="#999999" cx="8" cy="2" r="2" />
                        <circle fill="#999999" cx="14" cy="2" r="2" />
                    </svg>
                    </span>
                </paginate>
              </div>


            </div>
            <!-- /content-panel -->
          </div>
          <!-- /col-md-12 -->
        </div>
        <!-- /row -->
    </section>
    </section>
    </div>
</template>

<script>
import Default from '../Template/Default.vue';
import loading from 'vue-full-loading';
import Paginate from 'vuejs-paginate';

    export default {

            mounted() {
                this.getAllFoodOrders(this.$baseUrl+'api/admin/order/food/get_all');
            },

            components : {
                'app-default':Default,
                loading,
                'paginate' : Paginate
            },


            data(){
                return {
                    loading: false,
                    foodArray : [],
                    altFoodArray : [],
                    show: false,
                    label: 'Loading...',
                    overlay:true,
                    currentPage: 1,
                    perPage:10,
                    totalPages:10
                }
            },

            methods : {

                clickCallback (pageNum)  {
                this.currentPage = pageNum;
                console.log("currentPage is: ", this.currentPage);
                this.getAllFoodOrders(this.$baseUrl+'api/admin/order/food/get_all?page='+pageNum);
                    },

    // getRestaurantsNames(orders) {
    //     console.log("orders: ", orders);
    //     var restaurantNames = "";
    //     var phone = "";
    //     var address = "";
    //     for (let index = 0; index < orders.length; index++) {
    //         var restName = orders[index].name;
    //         address = orders[index].address;
    //         phone = orders[index].phone;

    //         var food = orders[index].food;
    //         var foodAndQty = "";
    //         var amount = 0;
    //         for (let i = 0; i < food.length; i++) {
    //             var item = "\t\nPrice : ₦" +  Number(Number(food[i].amount).toFixed(2)).toLocaleString() + " | Name: " + food[i].name + " | Qty: " +  food[i].quantity + ", ";
    //             foodAndQty += item;
    //             amount += Number(food[i].amount)
    //         }


    //         restaurantNames += index+1 +". Name: " + restName + " \t\nAddress:  " + address + " \t\nPhone:  " + phone + "  \t\nFoods: " + " ( "+  foodAndQty.slice(0, -2) + " ) \t\nTotal Price: ₦"+ Number(Number(amount).toFixed(2)).toLocaleString()  +"\t\n\t\n";
    //     }
    //      return restaurantNames;
    //    },


                assignRider(id){
                    this.$router.push('/assignRider/'+id);
                },


                viewRider(riderId, orderId){
                    var that = this;
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(this.$baseUrl+'api/admin/get_rider_by_id/'+riderId, options)
                    .then((response) => {
                        console.log(response);
                        this.show = false
                        this.$swal({
                        title: "Vendor S/N: "+response.data.vendor_id,
                        text: "Rider Phone & Name: "+response.data.phone_number+' | '+response.data.rider_name,
                        icon: "info",
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Edit Rider',
                        confirmButtonText: 'Okay',
                        }).then(function(result) {
                            if (result.isConfirmed) {
                                //send to server
                                console.log("okay clicked...");    
                            } else {
                                //do nothing
                                that.$router.push('/assignRider/'+orderId);
                            }
                        })

                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Fetch Rider Information", "error")
                        console.log(error);
                    });
                },

                getOrderedFoodsInfo(orders, delivery_amount, totalPrice) {

                        console.log("orders: ", orders);
                        var restaurantNames = "";
                        var phone = "";
                        var address = "";
                        
                        for (let index = 0; index < orders.length; index++) {
                                var restName = orders[index].name;
                                address = orders[index].address;
                                phone = orders[index].phone;

                                var food = orders[index].food;
                                var foodAndQty = "";
                                var amount = 0;
                                for (let i = 0; i < food.length; i++) {
                                    var item = "<b>Name:</b> " +  food[i].name + " | <b>Qty:</b> " + food[i].quantity + " | <b>Price:</b> ₦" +  Number(Number(food[i].amount).toFixed(2)).toLocaleString() + "<br> ";
                                    foodAndQty += item;
                                    amount += Number(food[i].amount)
                                }

                                let numb = index+1;
                                restaurantNames += "<br><b>Order "+ numb +"</b><br><b>Name:</b> " + restName + "<br><b>Address:</b>  " + address + "<br><b>Phone:</b>  " + phone + "<br><b>Foods:</b> <br>"+  foodAndQty + "<b>Price for Restaurant:</b> ₦"+ Number(Number(amount).toFixed(2)).toLocaleString()  +"<br><br>";


                        }


                                restaurantNames += "<br><b>Delivery Price:</b> ₦"+ Number(delivery_amount).toFixed(2).toLocaleString()  + "<br></b>Total Order Price:</b> ₦"+ Number(totalPrice).toFixed(2).toLocaleString() 
                                this.$swal({
                                title: "Order Info",
                                html: restaurantNames,
                                confirmButtonColor: '#3085d6'
                                })
                },




                    getSenderInfo(id) {
                            //MARK
                            console.log("id is: "+id);
                            this.show = true
                            let token = localStorage.getItem('delivery_app_token');
                            const tokenValue = "Bearer "+token;
                            const options = {
                                headers: {'Authorization': tokenValue}
                            };
                            this.axios.get(this.$baseUrl+'api/admin/get_user_by_id/'+id, options)
                            .then((response) => {
                                let user = response.data;
                                this.show = false
                                console.log("User is: ", user);
                                this.$swal({
                                title: "Name: "+user.first_name +" " + user.last_name,
                                text: "Phone: "+user.phone_number,
                                // icon: "info",
                                confirmButtonColor: '#3085d6'
                                })
                            }).catch(error => {
                                this.show = false
                                this.$swal("Error", "Failed to get sender info", "error")
                                console.log(error);
                            });
                    },

                getRestaurantDetails(id) {
                    //MARK
                    var that = this;
                    console.log("id is: "+id);
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(this.$baseUrl+'api/admin/restaurant/get_by_id/'+id, options)
                    .then((response) => {

                        this.show = false
                        console.log("restaurant response is: ", response.data);
                        this.$swal({
                        title: "Restaurant Name: "+response.data.name,
                        text: "Restaurant Phone & Address: "+response.data.phone_number+' | '+response.data.address,
                        icon: "info",
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Okay',
                        confirmButtonText: 'View Restaurant',
                        }).then(function(result) {
                            if (result.isConfirmed) {
                                that.$router.push('/restaurant/'+id);
                            } else {
                                console.log("okay clicked...");    
                            }
                        })
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Restaurant Info", "error")
                        console.log(error);
                    });
            },


                viewCompletedRider(riderId){
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(this.$baseUrl+'api/admin/get_rider_by_id/'+riderId, options)
                    .then((response) => {
                        console.log(response);
                        this.show = false
                        this.$swal({
                        title: "Vendor S/N: "+response.data.vendor_id,
                        text: "Rider Phone & Name: "+response.data.phone_number+' | '+response.data.rider_name,
                        icon: "info",
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Okay',
                        }).then(function(result) {
                            if (result.isConfirmed) {
                                console.log("okay clicked...");    
                            }
                        })

                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Fetch Rider Information", "error")
                        console.log(error);
                    });
                },


                gotoRestaurant(id){
                    this.$router.push('/restaurant/'+id);
                },

                getAllFoodOrders(url){
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    this.axios.get(url, options)
                    .then((response) => {
                        this.show = false
                        console.log(response.data);
                        this.altFoodArray = response.data.data;
                        this.foodArray = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.perPage = response.data.per_page;
                        this.totalPages = response.data.last_page;
                    }).catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Load Orders", "error")
                        console.log(error);
                    });
                },

                updateFoodOrder(id){
                      console.log(id);
                     this.$swal("Enter Food Status:", {
                        content: "input"
                     })
                        .then((value) => {
                        this.updateServer(value, id);
                        // this.$swal(`Confirm status as: ${value}`);
                     });     
                },
                
                updateServer(status, id) {
                    console.log("for id: "+ id);
                    console.log("status is: "+ status);
                    this.show = true
                    let token = localStorage.getItem('delivery_app_token');
                    const tokenValue = "Bearer "+token;
                    const options = {
                        headers: {'Authorization': tokenValue}
                    };
                    const formData = new FormData()
                    formData.append('id', id)
                    formData.append('status', status)


                    console.log("id: "+id);
                    console.log("status: "+status);


                    this.axios.post(this.$baseUrl+'api/admin/order/food/update_status', formData, options, {
                    }).then((res) => {
                        console.log(res)
                        this.show = false
                        this.$swal("Success", "Status Updated Successfully", "success")
                        this.getAllFoodOrders(this.$baseUrl+'api/admin/order/food/get_all?page='+this.currentPage);
                    })
                    .catch(error => {
                        this.show = false
                        this.$swal("Error", "Failed to Update status, try again", "error")
                        console.log(error);
                    });
                },

                confirmStatus(id, status) {
                    console.log(id);
                    const that = this;
                      this.$swal({
                            title: "Are you sure?",
                            text: "Once Food Order is confirmed or declined, its irreversible",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            cancelButtonText: 'No, go back!',
                            confirmButtonText: 'Yes, I am sure!',
                            }).then(function(result) {
                                if (result.isConfirmed) {
                                    //send to server
                                    that.updateConfirmToServer(id, status);  
                                } else {
                                    //do nothing
                                    console.log("cancelled do nothing...");
                                }
                            })
                },


                updateConfirmToServer(id, status){
                        console.log(id);
                        this.show = true
                        let token = localStorage.getItem('delivery_app_token');
                        const tokenValue = "Bearer "+token;
                        const options = {
                            headers: {'Authorization': tokenValue}
                        };
                        const formData = new FormData()
                        formData.append('id', id)
                        formData.append('is_delivered', status)
                        console.log("id: "+id);
                        console.log("is_delivered: "+status);
                        this.axios.post(this.$baseUrl+'api/admin/order/food/update', formData, options, {
                        }).then((res) => {
                            console.log(res)
                            this.show = false
                            this.$swal("Success", "Status Updated Successfully", "success")
                            this.getAllFoodOrders(this.$baseUrl+'api/admin/order/food/get_all?page='+this.currentPage);
                        })
                        .catch(error => {
                            this.show = false
                            this.$swal("Error", "Failed to Update status, try again", "error")
                            console.log(error);
                        });
                },

                allFoods(){
                    this.foodArray = this.altFoodArray;
                },

                pendingFoods(){
                    console.log("this.altFoodArray: ", this.altFoodArray);
                    const foods = this.altFoodArray.filter(ride => ride.status === 'PENDING'); //&& ride.cancel_request==0);
                    console.log("filtered foods: ", foods);
                    this.foodArray = foods;
                },

                runningFoods(){
                    const foods = this.altFoodArray.filter(ride => ride.status === 'PROCESSING');
                    this.foodArray = foods;
                },

                cancelledFoods(){
                    const foods = this.altFoodArray.filter(ride => ride.status === 'PENDING'); //&& ride.cancel_request==1);
                    this.foodArray = foods;
                },

                completedFoods(){
                    const foods = this.altFoodArray.filter(ride => ride.status === 'COMPLETED');
                    this.foodArray = foods;
                },

                declinedFoods(){
                    const foods = this.altFoodArray.filter(ride => ride.status === 'DECLINED');
                    this.foodArray = foods;
                },

            }


        
    }

</script>

<style lang="scss" scoped>

</style>